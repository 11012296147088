interface IProps {
  isCreate: boolean;
  isAdmin: boolean;
  profileOk?: boolean;
}

export const buildDescriptionText = ({ isCreate, profileOk, isAdmin }: IProps) => {
  let waitingApproval =
    !isCreate && !profileOk && !isAdmin
      ? "Se ha notificado al administrador para autorizar la creación de TFEs. "
      : "";
  let adminPassword = isAdmin && isCreate ? "Contraseña ImpulsoTFE" : "";
  return `Datos guardados correctamente. ${waitingApproval}${adminPassword}`;
};
