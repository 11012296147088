import { statusKey } from "../../utils/constants";

export const statusDisplayForCompany = (status: string) => {
  switch (status) {
    case statusKey.drafted:
      return "Borrador";
    case statusKey.presented:
      return "Presentado";
    case statusKey.accepted:
      return "Aceptado";
    case statusKey.rejected:
      return "Rechazado";
    case statusKey.toReview:
      return "En Revisión";
    case statusKey.signedAgreement:
      return "Aceptado";
    case statusKey.defended:
      return "Aceptado";
    case statusKey.assigned:
      return "Aceptado";
    default:
      return "";
  }
};
