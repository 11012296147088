import { FC } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../utils/routes";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";
import { role } from "../../../../../utils/roles";

export const HeaderTFE: FC = () => {
  const history = useHistory();

  let { userRole } = useSelector(({ user }: IStore) => user as IUser);

  const isAdmin = userRole === role.admin;
  const isCompany = userRole === role.company;

  return (
    <>
      <div className="row mb-2">
        <div className="col-10">
          <h5>Mis TFEs</h5>
        </div>
        {(isAdmin || isCompany) && (<div className="col-sm-2 col-12">
          <button
            className="button-save mb-4"
            onClick={() => history.push(ROUTES.Common.TFE.create)}
          >
            <FontAwesomeIcon className="mr-2" icon={faPlusCircle} />
            Nuevo TFE
          </button>
        </div>)}
      </div>
    </>
  );
};
