import { TAction } from "../types";
import produce from "immer";
import { ICompany } from "../../interfaces/ICompany";

const initialState: ICompany = {
  email: "",
  name: "",
  cif: "",
  website: "",
  province: "",
  district: "",
  address: "",
  postcode: "",
  representativeFullName: "",
  representativePosition: "",
  representativeEmail: "",
  representativePhone: "",
  profileOk: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: ICompany = initialState, action: TAction): ICompany =>
  produce(state, () => {
    switch (action.type) {
      case "SET_COMPANY":
        return action.payload;
      case "UNSET_COMPANY":
        return initialState;
      case "LOGOUT":
        return initialState;
      default:
        return state;
    }
  });
