/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";

//components
import { Header } from "../../../../components/Statistics/Header/Header";
import { SectionStatus } from "../../../../components/Statistics/Status/SectionStatus";
import { SectionCenters } from "../../../../components/Statistics/Centers/SectionCenters";
import { ListTFE } from "../../Common/ManageTFE/ListTFES/ListTFE";
import { paths } from "../../../../utils/paths";
import { getAll } from "../../../../utils/requests/dynamicReq";


export const Statistics: FC = () => {
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { STATISTICS } = paths;

  const [statusArray, setStatusArray] = useState([]);
  const [centerArray, setCenterArray] = useState([]);
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      let pathStatus = `${STATISTICS}/status`;
      let pathCenter = `${STATISTICS}/centers`;
      let pathCount = `${STATISTICS}/count`;
      const resStatusArray = await getAll(pathStatus, source);
      const resCenterArray = await getAll(pathCenter, source);
      const resCount = await getAll(pathCount, source);
      if (isMountedRef.current) {
        setStatusArray(resStatusArray);
        setCenterArray(resCenterArray);
        setCount(resCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-2">
          <div className="col-11 ">
            <h5>Estadísticas</h5>
          </div>
        </div>
        <Header count={count} />
        <SectionStatus data={statusArray} loading={loading} />
        <SectionCenters data={centerArray} loading={loading} />
        <div className="mt-5">
          <ListTFE />
        </div>
      </div>
    </>
  );
};
