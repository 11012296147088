/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { buildBadge } from "./utils";

interface IProps {
  status: string;
}

export const StatusBadge = ({ status }: IProps) => {
  const isMountedRef = useRef(false);

  const [bgColor, setBgColor] = useState("");
  const [label, setLabel] = useState("");

  useEffect(() => {
    isMountedRef.current = true;
    const { draftColor, draftLabel } = buildBadge(status);
    if (isMountedRef.current) {
      setBgColor(draftColor);
      setLabel(draftLabel);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [status]);

  const style = {
    minWidth: "40%",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: bgColor,
  };

  return <div style={style}>{label}</div>;
};
