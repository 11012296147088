import moment from "moment";

//prettier-ignore
export const formatDateTime = (dateTime: Date) => moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
//prettier-ignore
export const datetimeToDDBB = (date: Date) => moment(date).utc().format("YYYY-MM-DD HH:mm:ss");
export const dateToDDBB = (date: Date) => moment(date).utc().format("YYYY-MM-DD");
//prettier-ignore
export const utcToLocalTime = (date: Date) => moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss");
export const dateForTFE = (date: Date) => moment(date).format("DD-MM-YYYY");
export const formatDate = (date: Date) => moment(date).format("DD/MM/YYYY");
export const formatTime = (dateTime: Date) => moment(dateTime).format("HH:mm");

export const today = moment().format("YYYY-MM-DD HH:mm");
export const tomorrow = moment().add(1, "days").toString();
export const OneYearBefore = moment().subtract(1, "year").toString();
