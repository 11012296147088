/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from "react";
import "./App.css";

//middleware
import { useDispatch, useSelector } from "react-redux";
import { verify } from "jsonwebtoken";
import { Router, Switch, Route } from "react-router-dom";

//interfaces
import { IStore } from "./interfaces/IStore";
import { IUser } from "./interfaces/IUser";

//aux
import history from "./utils/history";
import { SetUserAction } from "./redux/actions";
import { jwtPassword } from "./utils/constants";

//views
// import { role } from "./utils/roles";
import LoggedRoutes from "./routes";
import { AccessWeb } from "./views/Unlogged/AccessWeb/AcessWeb";

import { ROUTES } from "./utils/routes";
import { ResetPassword } from "./views/Unlogged/GetNewPassword/ResetPassword";
import { ChangePassword } from "./views/Unlogged/GetNewPassword/ChangePassword";
import { Pivot } from "./views/Unlogged/Pivot";

const App: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      const sessionStorageToken = sessionStorage.getItem("token");
      sessionStorageToken && resetUser(sessionStorageToken);
    }
  }, [token]);

  const resetUser = (sessionStorageToken: string) => {
    try {
      //if token exists and is valid
      //prettier-ignore
      const { id, role, fullName, email, profileOk, active, studentId, degreeId }: any = verify(sessionStorageToken, jwtPassword);

      dispatch(
        SetUserAction({
          token: sessionStorageToken,
          id,
          userRole: role,
          isAdmin: role === role.admin,
          fullName,
          email,
          profileOk,
          studentId,
          active,
          degreeId
        })
      );
    } catch {
      //if token was fiddled with
      sessionStorage.removeItem("token");
      history.push("/");
    }
  };

  return (
    <Router history={history}>
      <Switch>
        {!token && (
          <>
            <Route exact path="/" component={AccessWeb} />
            <Route exact path={`${ROUTES.Pivot}/:token`} component={Pivot} />
            <Route exact path={ROUTES.Reset_Password} component={ResetPassword} />
            <Route exact path={`${ROUTES.Change_Password}/:id/:token` } component={ChangePassword} />
          </>
        )}
        {token && <LoggedRoutes />}
      </Switch>
    </Router>
  );
};

export default App;
