/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//middleware
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ICompany } from "../../../../interfaces/ICompany";
import Swal from "sweetalert2";
import { validate } from "./validations";
import { create, getById, update } from "../../../../utils/requests/dynamicReq";
import { duplicateEmail, profileNotOk, SwalError } from "../../../../utils/constants";
import { paths } from "../../../../utils/paths";
import { useDispatch, useSelector } from "react-redux";
import { LogoutAction } from "../../../../redux/actions";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import sha1 from "sha1";
import { role } from "../../../../utils/roles";
import { ROUTES } from "../../../../utils/routes";
import { buildDescriptionText } from "./utils";

//utils

export const ManageCompany: FC = () => {
  const { COMPANY } = paths;

  let { profileOk, userRole } = useSelector(({ user }: IStore) => user as IUser);

  const history = useHistory();
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);
  const { id }: any = useParams();

  const source = Axios.CancelToken.source();

  const [isCreate, setIsCreate] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cif, setCif] = useState("");
  const [website, setWebsite] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [representativeFullName, setRepresentativeFullName] = useState("");
  const [representativePosition, setRepresentativePosition] = useState("");
  const [representativeEmail, setRepresentativeEmail] = useState("");
  const [representativePhone, setRepresentativePhone] = useState("");
  const [active, setActive] = useState<boolean>();

  const [message, setMessage] = useState("");

  let defaultPassword = "ImpulsoTFE";

  useEffect(() => {
    isMountedRef.current = true;
    setIsAdmin(userRole === role.admin);
    const { pathname }: any = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadEditData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [isCreate]);

  const loadEditData = async () => {
    try {
      let responseCompany: ICompany = await getById(COMPANY, id, source);
      let isAdminLocal = userRole === role.admin;
      isMountedRef.current && build(responseCompany);

      const { active } = responseCompany;
      if (!profileOk && !active && !isAdminLocal)
        Swal.fire({
          icon: "warning",
          text: profileNotOk,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const build = (c: ICompany) => {
    setName(c.name || "");
    setEmail(c.email || "");
    setCif(c.cif || "");
    setWebsite(c.website || "");
    setProvince(c.province || "");
    setDistrict(c.district || "");
    setAddress(c.address || "");
    setPostcode(c.postcode || "");
    setRepresentativeFullName(c.representativeFullName || "");
    setRepresentativePosition(c.representativePosition || "");
    setRepresentativeEmail(c.representativeEmail || "");
    setRepresentativePhone(c.representativePhone || "");
    setActive(c.active);
  };

  //--------------------------------

  const handleSubmit = async () => {
    const company: ICompany = buildToSend();
    console.log(company);
    const notValid = validate(company);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una empresa?" : "guardar estos datos?";
    Swal.fire({
      icon: "question",
      text: `¿Quiere ${question}`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(company);
    });
  };

  const handleConfirmation = (company: ICompany) => {
    let createOrUpdate = isCreate
      ? create(COMPANY, company, source)
      : update(COMPANY, id, company, source);
    createOrUpdate
      .then(async () => {
        isMountedRef.current && setIsCreate(false);
        //prettier-ignore
        const descriptionText = buildDescriptionText({isCreate, isAdmin, profileOk});
        const { isConfirmed } = await Swal.fire({
          icon: "success",
          text: descriptionText,
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        isConfirmed && routeUser();
      })
      .catch((error) => {
        let errorMessage = SwalError;
        if(error.response.data.status === "duplicate")
          errorMessage = duplicateEmail;        
        Swal.fire({ icon: "error", text: errorMessage });
      });
  };

  const routeUser = () => {
    if (isAdmin) return history.push(ROUTES.Admin.Company.all);
    if (!profileOk) {
      dispatch(LogoutAction());
      sessionStorage.removeItem("token");
      history.push("/");
      setTimeout(() => window.location.reload(), 250);
    }
  };
  const buildToSend = () => ({
    name,
    email: email.trim(),
    password: isAdmin && isCreate ? sha1(defaultPassword) : undefined,
    cif,
    website,
    province,
    district,
    address,
    postcode,
    representativeFullName,
    representativePosition,
    representativeEmail: representativeEmail.trim(),
    representativePhone,
    profileOk: true,
    active: isAdmin && isCreate ? true : active,
  });

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-2">
          <div className="col-11 d-flex justify-content-between">
            <h5>Empresa</h5>
            <span className="text-danger">{message}</span>
            <button className="button-search" onClick={handleSubmit}>
              <FontAwesomeIcon className="mr-2" icon={faSave} />
              Guardar
            </button>
          </div>
        </div>
        {profileOk && !active && !isAdmin && (
          <span className="text-danger">
            *Su empresa está a la espera de ser validada
          </span>
        )}

        <div className="row mt-4">
          <div className="col-sm-11 col-12 whiteDiv">
            <div className="d-flex justify-content-center">
              <h6>Información de la empresa</h6>
            </div>
            <div className="form-row">
              <div className="col-sm-4 col-12">
                <small>*Nombre</small>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </div>
              <div className="col-sm-4 col-12">
                <small>*Email</small>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>
              <div className="col-sm-4 col-12">
                <small>
                  Página web <strong>(asegúrate de empezar con https://)</strong>
                </small>
                <input
                  type="text"
                  className="form-control"
                  value={website}
                  onChange={({ target: { value } }) => setWebsite(value)}
                />
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-sm-4 col-12">
                <small>*CIF</small>
                <input
                  type="text"
                  className="form-control"
                  value={cif}
                  onChange={({ target: { value } }) => setCif(value)}
                />
              </div>

              <div className="col-sm-4 col-12">
                <small>*Localidad</small>
                <input
                  type="text"
                  className="form-control"
                  value={district}
                  onChange={({ target: { value } }) => setDistrict(value)}
                />
              </div>
              <div className="col-sm-4 col-12">
                <small>*Provincia</small>
                <input
                  type="text"
                  className="form-control"
                  value={province}
                  onChange={({ target: { value } }) => setProvince(value)}
                />
              </div>
            </div>

            <div className="form-row mt-2">
              <div className="col-sm-4 col-12">
                <small>*Dirección</small>
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={({ target: { value } }) => setAddress(value)}
                />
              </div>
              <div className="col-sm-8 col-12">
                <small>*CP</small>
                <input
                  type="text"
                  className="form-control"
                  value={postcode}
                  onChange={({ target: { value } }) => setPostcode(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-11 col-12 whiteDiv">
            <div className="d-flex justify-content-center">
              <h6>Datos representante de la empresa</h6>
            </div>
            <div className="form-row">
              <div className="col-12">
                <small>*Nombre completo</small>
                <input
                  type="text"
                  className="form-control"
                  value={representativeFullName}
                  onChange={({ target: { value } }) =>
                    setRepresentativeFullName(value)
                  }
                />
              </div>
            </div>
            <div className="form-row mt-2">
              <div className="col-12">
                <small>*Correo electrónico</small>
                <input
                  type="text"
                  className="form-control"
                  value={representativeEmail}
                  onChange={({ target: { value } }) => setRepresentativeEmail(value)}
                />
              </div>
            </div>

            <div className="form-row mt-2">
              <div className="col-sm-4 col-12">
                <small>*Cargo</small>
                <input
                  type="text"
                  className="form-control"
                  value={representativePosition}
                  onChange={({ target: { value } }) =>
                    setRepresentativePosition(value)
                  }
                />
              </div>
              <div className="col-sm-8 col-12">
                <small>*Teléfono</small>
                <input
                  type="text"
                  className="form-control"
                  value={representativePhone}
                  onChange={({ target: { value } }) => setRepresentativePhone(value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
