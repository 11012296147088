/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { More } from "../../../components/More/More";

//utils
import { InboxList } from "../../../components/InboxList/InboxList";
import { ICenter } from "../../../interfaces/ICenter";
import { ITFEDDBB } from "../../../interfaces/ITFEDDBB";
import { paths } from "../../../utils/paths";
import { getAll } from "../../../utils/requests/dynamicReq";
import { tfeValue } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { role } from "../../../utils/roles";
import { buildFilter } from "./ManageTFE/utils";

//utils

export const Inbox: FC = () => {
  let { userRole, id: userId } = useSelector(({ user }: IStore) => user as IUser);

  const { CENTERS, INBOX } = paths;

  const [tfes, setTfes] = useState<ITFEDDBB[]>([]);
  const [displayTfes, setDisplayTfes] = useState<ITFEDDBB[]>([]);
  const [centers, setCenters] = useState<ICenter[]>([]);

  //Filters
  const [type, setType] = useState("");
  const [pickedCenter, setPickedCenter] = useState("");

  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    setIsLoading(true);
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadDefaultData = async () => {
    userId = userRole !== role.admin ? userId : 0;
    try {
      const filter: string = buildFilter({ userRole, userId });
      const tfes = await getAll(INBOX, source, filter);
      let responseCenters = await getAll(CENTERS, source);

      if (isMountedRef.current) {
        setIsLoading(false);
        setTfes(tfes);
        setDisplayTfes(tfes);
        //ordering them alphabetically
        //prettier-ignore
        responseCenters.sort((a: ICenter, b: ICenter) => a.name.localeCompare(b.name))
        setCenters(responseCenters);
      }
    } catch (error) {
      isMountedRef.current && setIsLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  const filterType = (type: string) => {
    setType(type);
    multipleFilter(type, pickedCenter);
  };

  const filterCenter = (pickedCenter: string) => {
    setPickedCenter(pickedCenter);
    multipleFilter(type, pickedCenter);
  };

  const multipleFilter = (type: string, pickedCenter: string) => {
    setTfes(
      displayTfes.filter(({ tfeStatus, tfeType, center }: ITFEDDBB) => {
        return (
          (type === "" || tfeType === type) &&
          (pickedCenter === "" || center?.name === pickedCenter)
        );
      })
    );
  };

  const cleanFilters = () => {
    setType("");
    setPickedCenter("");
    setTfes(displayTfes);
  };

  const admin = userRole === role.admin;
  const coordinator = userRole === role.cordinator;
  let noDataMessage = "";
  if (coordinator) {
    noDataMessage =
      "-Dentro del programa Impulso TFE, no se ha encontrado ninguna oferta de TFE vinculado a su centro-";
  }

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h5>Mis TFEs</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-12">
            <select
              className="form-control"
              value={type}
              onChange={({ target: { value } }) => filterType(value)}
            >
              <option value={""}>Tipo</option>
              <option value={tfeValue.tfg}>TFG</option>
              <option value={tfeValue.tfm}>TFM</option>
            </select>
          </div>

          {admin && (
            <div className="col-sm-3 col-12">
              <select
                className="form-control"
                value={pickedCenter}
                onChange={({ target: { value } }) => filterCenter(value)}
              >
                <option value={""}>Centro</option>
                {centers.map(({ name, id }) => (
                  <option key={id} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="col-sm-3 col-12">
            <button className="button-search mt-2 mr-5" onClick={cleanFilters}>
              Quitar filtros
            </button>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <InboxList tfes={tfes} index={index} />
            <More
              displayData={displayTfes}
              isLoading={isLoading}
              refreshIndex={setIndex}
              index={index}
              amount={6}
              noDataMessage={noDataMessage}
            />
          </div>
        </div>
      </div>
    </>
  );
};
