import { validateEmail } from "../../utils/validations";

//prettier-ignore
export const validate = (name: string, email: string, password: string, repeatPassword: string, tAndC: boolean) => {
  if (!tAndC) return "Debe aceptar los Términos y Condiciones";
  if (!email || !password || !repeatPassword || !name)
      return "Por favor rellene todos los campos";
  if (!validateEmail(email))      
     return "Por favor escriba un email válido";
  if (password !== repeatPassword)
     return "Las contraseñas no coinciden";
};
