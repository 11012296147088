import { IStatus } from "../interfaces/IStatus";

export const jwtPassword = (process.env.REACT_APP_JWT_PSS as string) || "1234";

export const baseURL = process.env.REACT_APP_API || "http://localhost:3000/";

export const SwalError =
  "Ha habido un error, por favor intente de nuevo más tarde o contacte con su administrador.";

export const NewCompanySuccess =
  "Empresa creada correctamente, por favor complete su perfil para crear un TFE.";

export const requiredInputs = "Por favor, rellene todos los campos con *";

export const profileNotActive =
  "Su cuenta está a la espera de ser validada por la universidad. Cuando esté activa se lo notificaremos y podrá crear TFE. Perdone las molestias.";

export const datesNotValid =
  "La fecha de fin no puede ser menor a la de inicio.";
export const profileNotOk =
  "Por favor complete su perfil y aguarde a ser validado. Cuando esté activo se lo notificaremos y podrá crear TFE. Perdone las molestias.";

export const statusKey: IStatus = {
  drafted: "drafted",
  presented: "presented",
  accepted: "accepted",
  rejected: "rejected",
  toReview: "to_review",
  defended: "defended",
  assigned: "assigned",
  signedAgreement: "signed_agreement",
};

export const tfeType = {
  tfg: "Trabajo de fin de grado",
  tfm: "Trabajo de fin de masters",
};

export const tfeValue = {
  tfg: "tfg",
  tfm: "tfm",
};

export const styleIsAllowed = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed" : "",
});

export const statusColor: IStatus = {
  drafted: "#db8a47",
  presented: "#C4C4C4",
  accepted: "#219653",
  rejected: "#a32929",
  toReview: "#CD176F",
  defended: "#242424",
  assigned: "#12385E",
  signedAgreement: "#5D6163",
};

export const statusColorIndex: { name: string; label: string; color: string }[] = [
  { name: "drafted", label: "Borrador", color: "#db8a47" },
  { name: "presented", label: "Presentado", color: "#C4C4C4" },
  { name: "accepted", label: "Aceptado", color: "#219653" },
  { name: "rejected", label: "Rechazado", color: "#a32929" },
  { name: "to_review", label: "En Revisión", color: "#CD176F" },
  { name: "defended", label: "Defendido", color: "#242424" },
  { name: "assigned", label: "Asignado", color: "#12385E" },
  { name: "signed_agreement", label: "Convenio Firmado", color: "#5D6163" },
];


//NOTE - importante to keep this array up to date with latest centers
export const centerColorIndex: { name: string; color: string }[] = [
  {
    name: "Escuela de Ingenierías Industriales",
    color: "#C4C4C4",
  },
  {
    name: "Escuela Técnica Superior de Arquitectura",
    color: "#f7f700",
  },
  {
    name: "Escuela Técnica Superior de Ingeniería de Telecomunicación",
    color: "#219653",
  },
  {
    name: "Escuela Técnica Superior de Ingeniería Informática",
    color: "#189BCC",
  },
  {
    name: "Facultad de Bellas Artes",
    color: "#12385E",
  },
  {
    name: "Facultad de Ciencias",
    color: "#5D6163",
  },
  {
    name: "Facultad de Ciencias de la Comunicación",
    color: "#740924",
  },
  {
    name: "Facultad de Ciencias de la Educación",
    color: "#179894",
  },
  {
    name: "Facultad de Ciencias de la Salud",
    color: "#5D6163",
  },
  {
    name: "Facultad de Ciencias Económicas y Empresariales",
    color: "#F87B03",
  },
  {
    name: "Facultad de Comercio y Gestión",
    color: "#9C2F1D",
  },
  {
    name: "Facultad de Derecho",
    color: "#DE2102",
  },
  {
    name: "Facultad de Estudios Sociales y del Trabajo",
    color: "#CD176F",
  },
  {
    name: "Facultad de Filosofía y Letras",
    color: "#797A95",
  },
  {
    name: "Facultad de Medicina",
    color: "#25A9AF",
  },
  {
    name: "Facultad de Psicología y Logopedia",
    color: "#FF8ECC",
  },
  {
    name: "Facultad de Turismo",
    color: "#FF962D",
  },
  {
    name: "Escuela Politécnica Superior",
    color: "#CD176F",
  },
];

export const tokenNotFound = "not-found"

export const duplicateEmail = "El email que está ingresando ya se encuentra registrado en nuestro sistema. Por favor intente con otro email."
