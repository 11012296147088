import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { IUser } from "../../interfaces/IUser";
import { parseTfeType } from "../../utils/auxiliar/strings";
import { setEditAllowed } from "../../views/Logged/Common/ManageTFE/utils";
import { StatusBadge } from "../StatusBadge/StatusBadge";

interface IProps {
  tfe: ITFEDDBB;
}

export const GeneralInfo = ({ tfe }: IProps) => {

  const { userRole } = useSelector(({ user }: IStore) => user as IUser);

  //prettier-ignore
  const {title, description, objectives, tfeType, center, company, observations, tfeStatus } = tfe;

  const editAllowed = setEditAllowed(userRole);

  return (
    <>
      <div className="row ">
        <div className="col-sm-8 col-12 d-flex justify-content-end">
          <h5>Información general del TFE</h5>
        </div>
        <div className="col-sm-4 col-12 d-flex justify-content-end">
          {editAllowed && <StatusBadge status={tfeStatus!} />}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-4 col-12">
          <h6>Tipo de Trabajo Fin de Estudios</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{parseTfeType(tfeType)}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4 col-12">
          <h6>Centro al que está adscrito el TFE</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{center?.name}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4 col-12">
          <h6>Empresa</h6>
        </div>
        <div className="col-sm-4 col-12">
          {company?.website ? (
            <a href={company?.website} target="_blank" rel="noreferrer">
              {company?.name}
            </a>
          ) : (
            <span>{company?.name}</span>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h6>Título</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>{title}</p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <h6>Descripción</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>{description}</p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <h6>Objetivos</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>{objectives}</p>
        </div>
      </div>

    {observations &&
    <>
      <div className="row mt-4">
        <div className="col-12">
          <h6>Observaciones</h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>{observations}</p>
        </div>
      </div>
    </>}
    </>
  );
};
