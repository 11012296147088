/* eslint-disable react-hooks/exhaustive-deps */

//middleware
import { useHistory } from "react-router";
import moment from "moment";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { dateForTFE } from "../../utils/auxiliar/dates";
import { switchStatus } from "../../utils/auxiliar/switchs";
import { ROUTES } from "../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { role } from "../../utils/roles";
import { statusKey } from "../../utils/constants";

interface IProps {
  tfes: ITFEDDBB[];
  index: number;
}

export const InboxList = ({ tfes, index }: IProps) => {
  const {  userRole } = useSelector(({ user }: IStore) => user as IUser);

  const isAdmin = userRole === role.admin;

  const history = useHistory();

  const buildTfe = (tfe: any, i: number) => {

    const {id, tfeType, presentedDate, company, title, tfeStatus} = tfe;

    if (isAdmin && tfeStatus === statusKey.drafted) return ;
    else return <tr key={i}>
                  <td>
                    <strong>{`#${moment().year()}-${id}`} </strong>
                  </td>
                  <td>{tfeType.toLocaleUpperCase()}</td>
                  <td>{dateForTFE(presentedDate!)}</td>
                  <td>{company?.name}</td>
                  <td>{title}</td>
                  <td>{switchStatus(tfeStatus)}</td>
                  <td
                    className="pointer c-lightblue"
                    onClick={() =>
                      history.push(`${ROUTES.Common.TFE.read}/${id}`)
                    }
                  >
                    <strong>Ver</strong>
                  </td>
                </tr>
  }

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Código TFE</th>
            <th scope="col">Tipo</th>
            <th scope="col">Fecha de propuesta</th>
            <th scope="col">Empresa</th>
            <th scope="col">Título</th>
            <th scope="col">Estado</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {tfes?.slice(0, index)?.map((tfe, i: any) => (buildTfe(tfe, i)))}
        </tbody>
      </table>
    </>
  );
};
