/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

//middleware
import Axios from "axios";
import { TFEsTable } from "../../../../../components/TFEsTable/TFEsTable";
import { More } from "../../../../../components/More/More";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { paths } from "../../../../../utils/paths";
import { getAll, update } from "../../../../../utils/requests/dynamicReq";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import moment from "moment";

//utils
import {
  datesNotValid,
  statusKey,
  SwalError,
  tfeValue,
} from "../../../../../utils/constants";
import { switchStatus } from "../../../../../utils/auxiliar/switchs";
import { ICompany } from "../../../../../interfaces/ICompany";
import { OneYearBefore } from "../../../../../utils/auxiliar/dates";
import { ITFEDDBB } from "../../../../../interfaces/ITFEDDBB";
import { IStore } from "../../../../../interfaces/IStore";
import { IUser } from "../../../../../interfaces/IUser";
import { role } from "../../../../../utils/roles";
import { buildCsv, buildFilter, csvHeaders } from "../utils";
import { ICsv } from "../../../../../interfaces/ICsv";

//css
import styles from "./ListTFES.module.css"; // Import css modules stylesheet as styles
registerLocale("es", es);

export const ListTFE: FC = () => {
  let { userRole, id: userId } = useSelector(({ user }: IStore) => user as IUser);

  const { TFE, COMPANY } = paths;

  const [tfes, setTfes] = useState<ITFEDDBB[]>([]);
  const [displayTfes, setDisplayTfes] = useState<ITFEDDBB[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [cvsData, setCSVData] = useState<ICsv[]>([]);

  //Filters
  const [startDate, setStartDate] = useState(new Date(OneYearBefore));
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("");
  const [company, setCompany] = useState("");
  const [status, setStatus] = useState("");

  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompany, setIsCompany] = useState(false);

  const [message, setMessage] = useState("");
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    setIsCompany(userRole === role.company ? true : false);
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadDefaultData = async () => {
    try {
      userId = userRole !== role.admin ? userId : 0;
      const filter: string = buildFilter({ userRole, userId, startDate, endDate });
      const responseTfes = await getAll(TFE, source, filter);
      const responseCompanies = await getAll(COMPANY, source);

      if (isMountedRef.current) {
        setTfes(responseTfes);
        setDisplayTfes(responseTfes);
        setCompanies(responseCompanies);
        setIsLoading(false);

        //csv
        setCSVData(buildCsv(responseTfes));
      }
    } catch (error) {
      setIsLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  const handleNewDates = () => {
    if (endDate < startDate) {
      setMessage(datesNotValid);
      setTimeout(() => setMessage(""), 4000);
    } else {
      loadDefaultData();
    }
  };

  //------------------------------------------

  const filterType = (type: string) => {
    setType(type);
    multipleFilter(type, status, company);
  };

  const filterStatus = (status: string) => {
    setStatus(status);
    multipleFilter(type, status, company);
  };

  const filterCompany = (company: string) => {
    setCompany(company);
    multipleFilter(type, status, company);
  };

  const multipleFilter = (type: string, status: string, pickedCompany: string) => {
    setTfes(
      displayTfes.filter(({ tfeStatus, tfeType, company }: ITFEDDBB) => {
        return (
          (type === "" || tfeType === type) &&
          (status === "" || tfeStatus === status) &&
          (pickedCompany === "" || company?.name === pickedCompany)
        );
      })
    );
  };

  const cleanFilters = () => {
    setStatus("");
    setType("");
    setCompany("");
    setTfes(displayTfes);
  };

  //------------------------------------------

  const updateTfe = (tfeId: number) => {
    let updatedTfe = tfes.filter((c) => c.id === tfeId);
    let tfe = buildTfe(updatedTfe);

    handleSubmit(tfe, tfeId);
  };

  const handleSubmit = (buildCentre: any, centreId: number) => {
    update(TFE, centreId, buildCentre, source)
      .then(async () => {
        Swal.fire({
          icon: "success",
          text: `Estado del TFE cambiado correctamente.`,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const buildTfe = (updatedTfe: ITFEDDBB[]) => ({
    active: updatedTfe[0]?.active,
  });

  const coordinator = userRole === role.cordinator;
  const delegate = userRole === role.delegate;
  const admin = userRole === role.admin;

  return (
    <>
      {!isCompany && (
        <div className="row mb-2">
          <div className="col-sm-2 col-12">
            <DatePicker
              className="form-control"
              selected={startDate!}
              onChange={(date: Date) => setStartDate(date!)}
              placeholderText="Día actual"
              dateFormat="dd/MM/yyyy"
              locale="es"
            />
          </div>
          <div className="col-sm-2 col-12">
            <DatePicker
              className="form-control"
              selected={endDate ? endDate : startDate}
              onChange={(date: Date) => setEndDate(date!)}
              placeholderText="Hace un año"
              dateFormat="dd/MM/yyyy"
              locale="es"
            />
          </div>

          <div className="col-sm-2 col-12">
            <button
              style={{ width: "6rem" }}
              className={styles.btnIcon}
              onClick={handleNewDates}
            >
              <FontAwesomeIcon className="mr-3" icon={faPaperPlane} /> Aplicar
            </button>
          </div>

          <div className="col-sm-5 col-12 text-danger">
            <span>{message}</span>
          </div>
        </div>
      )}

      {!isCompany && (
        <div className="row">
          <div className="col-sm-2 col-12">
            <select
              className="form-control"
              value={type}
              onChange={({ target: { value } }) => filterType(value)}
            >
              <option value={""}>Tipo</option>
              <option value={tfeValue.tfg}>TFG</option>
              <option value={tfeValue.tfm}>TFM</option>
            </select>
          </div>

          <div className="col-sm-2 col-12">
            <select
              className="form-control"
              value={status}
              onChange={({ target: { value } }) => filterStatus(value)}
            >
              <option value={""}>Estado</option>
              <option value={statusKey.presented}>
                {switchStatus(statusKey.presented)}
              </option>
              <option value={statusKey.accepted}>
                {switchStatus(statusKey.accepted)}
              </option>
              <option value={statusKey.toReview}>
                {switchStatus(statusKey.toReview)}
              </option>
              <option value={statusKey.assigned}>
                {switchStatus(statusKey.assigned)}
              </option>
              <option value={statusKey.rejected}>
                {switchStatus(statusKey.rejected)}
              </option>
              <option value={statusKey.signedAgreement}>
                {switchStatus(statusKey.signedAgreement)}
              </option>
              <option value={statusKey.defended}>
                {switchStatus(statusKey.defended)}
              </option>
            </select>
          </div>

          {admin && (
            <div className="col-sm-2 col-12">
              <select
                className="form-control"
                value={company}
                onChange={({ target: { value } }) => filterCompany(value)}
              >
                <option value={""}>Empresa</option>
                {companies.map(({ name, id }) => (
                  <option key={id} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="col-sm-2 col-12">
            <button
              style={{ width: "6rem" }}
              className={styles.btnIcon}
              onClick={cleanFilters}
            >
              Quitar filtros
            </button>
          </div>
          <div className="col align-items-center" />
          {(coordinator || delegate || admin) && (
            <CSVLink
              data={cvsData}
              headers={csvHeaders}
              filename={`Propuestas idea Impulso TFE de-${moment(startDate)
                .local()
                .format("DD-MM-YYYY")}-a-${moment(endDate)
                .local()
                .format("DD-MM-YYYY")}.csv`}
            >
              <button style={{ width: "3.5rem" }} className={styles.btnIcon}>
                <FontAwesomeIcon
                  className=""
                  icon={faFileCsv}
                  style={{ fontSize: "1.2rem" }}
                />{" "}
              </button>
            </CSVLink>
          )}
        </div>
      )}

      <div className="row mt-4">
        <div className="col-12 whiteDiv overFlowAuto">
          <TFEsTable
            tfes={tfes}
            index={index}
            setTfes={setTfes}
            updateTfe={updateTfe}
          />
          <More
            displayData={displayTfes}
            isLoading={isLoading}
            refreshIndex={setIndex}
            index={index}
            amount={6}
          />
        </div>
      </div>
    </>
  );
};
