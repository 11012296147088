/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

//css
import "./../Sidebar.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  faSignOutAlt,
  faCalendar,
  faBell,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { role } from "../../../utils/roles";

interface IProps {
  logout: () => void;
}

export const CoordinatorSidebar = (props: IProps) => {
  //prettier-ignore
  let {  userRole } = useSelector(({ user }: IStore) => user as IUser);
  const { logout } = props;


  const coordinator = userRole === role.cordinator;

  return (
    <Nav className="navbar navbar-expand-md navbar-dark  align-items-start flex-md-column flex-row bg-blue ">
    <a
      // href="javascript:void(0);"
      href="void(0);"
      className="navbar-toggler"
      data-toggle="collapse"
      data-target="#sidebarID"
    >
      <span className="navbar-toggler-icon"></span>
    </a>
    <div className="collapse navbar-collapse sidebar" id="sidebarID">
      <ul className="flex-column navbar-nav w-100 justify-content-between">
        <li>
          <Nav.Item>
            <NavLink className="nav-link pl-0" to={ROUTES.Common.Inbox.all}>
              <FontAwesomeIcon className="iconClass mr-3" icon={faBell} />
              <span className="">Buzón TFE</span>
            </NavLink>
          </Nav.Item>
        </li>
        <li>
          <Nav.Item>
            <NavLink className="nav-link pl-0" to={ROUTES.Common.TFE.all}>
              <FontAwesomeIcon className="iconClass mr-3" icon={faCalendar} />
              <span className="">Listado TFE</span>
            </NavLink>
          </Nav.Item>
        </li>
        {coordinator && (<li>
          <Nav.Item>
            <NavLink className="nav-link pl-0" to={ROUTES.Common.Coordinators.all}>
              <FontAwesomeIcon className="iconClass mr-3" icon={faVial} />
              <span className="">Coordinación</span>
            </NavLink>
          </Nav.Item>
        </li>)}
        <li className="nav-item responsiveLogout" onClick={logout}>
          <a className="nav-link pl-0" href="#">
            <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
            <span className="">Cerrar sesión</span>
          </a>
        </li>
      </ul>
    </div>
  </Nav>
  );
};
