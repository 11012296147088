/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

//middleware
import Axios from "axios";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { Form } from "react-bootstrap";
import { role } from "../../utils/roles";

interface IProps {
  coordinators: any[];
  index: number;
  setCoordinators: any;
  updateCoordinator: (coordinatorsId:number, userRole: string, message: string) => void;
}

export const CoordinationTable = ({
  coordinators,
  index,
  setCoordinators,
  updateCoordinator,
}: IProps) => {
  let {  userRole } = useSelector(({ user }: IStore) => user as IUser);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const settingActiveToggle = (i:any, userRole: string) => {
    // eslint-disable-next-line eqeqeq
    if (coordinators[i].active == false) {
      let message = "activado";
      let coordinatorsId = coordinators[i].id;
      let newCoordinator = [...coordinators];
      newCoordinator[i].active = true;
      setCoordinators(newCoordinator);
      setTimeout(() => {
        updateCoordinator(coordinatorsId!, userRole, message);
      }, 200);
    // eslint-disable-next-line eqeqeq
    }else if(coordinators[i].active == true){
      let message = "desactivado";
      let coordinatorsId = coordinators[i].id;
      let newCoordinator = [...coordinators];
      newCoordinator[i].active = false;
      setCoordinators(newCoordinator);
      setTimeout(() => {
        updateCoordinator(coordinatorsId!, userRole, message);
      }, 200);
    }
  };

  const isAdmin = true ? userRole === role.admin : false;

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Cargo</th>
            <th scope="col">Centro</th>
            {isAdmin && <th scope="col">Activo</th>}
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {coordinators
            ?.slice(0, index)
            ?.map(({ id, fullName, centers, userRole, active }: any, i: any) => (
              <tr key={i}>
                <td>{fullName}</td>
                <td>{userRole}</td>
                <td>{centers[0]?.name}</td> 
                {isAdmin &&(<td>
                      <Form>
                        <Form.Check
                          type="switch"
                          id={i+1}
                          disabled={userRole === "Coordinador"}
                          checked={active}
                          onChange={() => 
                            settingActiveToggle(i, userRole)
                          }
                        />
                      </Form>
                    </td>)}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
