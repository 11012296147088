/* eslint-disable react-hooks/exhaustive-deps */
import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { stringShortener } from "../../utils/auxiliar/strings";
import { ROUTES } from "../../utils/routes";
import { checkIfSelected } from "../../views/Logged/Common/ManageTFE/utils";


interface IProps {
  tfe: ITFEDDBB;
  selectedTfes?: ITFEDDBB[];
  allowsSelection: boolean;
}

export const StudentTFE = ({ tfe, selectedTfes, allowsSelection }: IProps) => {
  const {student} = tfe;
  const history = useHistory();

  const { id, title, description, company } = tfe;

  const selected = checkIfSelected(id, selectedTfes);

  return (
    <>
      <div className="row">
        <div className="col-md-8 col-12">
          <h6>{title}</h6>
        </div>
        <div className="col-md-4 col-12">
          <div className="d-flex">
            <button
              className="button-save mr-2"
              onClick={() => history.push(`${ROUTES.Common.TFE.read}/${id}`)}
            >
              Más Info.
            </button>
            {allowsSelection && selected && !student && (
              <button className="button-cancel" style={{cursor: "unset"}}>Interés mostrado</button>
            )}
            {student && (
              <button className="button-cancel" style={{cursor: "unset"}}>TFE ya asignado</button>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-6 col-12">
          <FontAwesomeIcon className="mr-3 mt-1" icon={faIndustry} />
          <span className="mr-3">{company.name}</span>
          <a href={tfe?.company?.website} target="_blank" rel="noreferrer">
            {tfe?.company?.website}
          </a>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <p>{stringShortener(description, 80)}</p>
        </div>
      </div>
    </>
  );
};
