import { FC, useState } from "react";

//images
import logo from "../../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";

import { Link, useHistory } from "react-router-dom";
import { validate } from "./validations";
import { paths } from "../../../utils/paths";
import Swal from "sweetalert2";
import { SwalError } from "../../../utils/constants";
import { create } from "../../../utils/requests/dynamicReq";
import Axios from "axios";

//aux

export const ResetPassword: FC = () => {
  const history = useHistory();

  const { FORGOTPSS } = paths;
  const source = Axios.CancelToken.source();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const notValid = validate(email);
    if (notValid) {
      throwError(notValid);
      return;
    }
    Swal.fire({
      icon: "question",
      text: `¿Quieres reiniciar la contraseña para el email ${email}?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation();
    });
  };

  const handleConfirmation = async () => {
    setLoading(true);
    create(FORGOTPSS, { email }, source)
      .then(() => {
        history.push("/");
        Swal.fire({
          icon: "success",
          text: "Por favor ingrese a su email y siga los pasos. Recuerde chequear su correo no deseado",
        });
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({ icon: "error", text: SwalError });
      });
  };

  const throwError = (message: string) => {
    setLoading(false);
    setMessage(message);
    setTimeout(() => setMessage(""), 4000);
  };

  const disabled = !email;

  return (
    <div className="resetPasswordContainer">
      <div className="cardResetPassword">
        <img className="loginLogo mb-2" src={logo} alt="" />
        <h5>¿Olvidaste tu contraseña?</h5>
        <p className="textCardPassword">
          Introduce tu correo electrónico y te enviaremos un enlace para que
          recuperes tu cuenta.
        </p>

        <small>Email</small>
        <input
          className="form-control input100"
          type="text"
          placeholder="Introducir email"
          value={email}
          onChange={({ target: { value } }) => {
            setMessage("");
            setEmail(value);
          }}
        />
        {message && <span className="text-center">{message}</span>}
        {/* prettier-ignore */}
        <button
            style={{ cursor: disabled ? "not-allowed" : "" }}
            className="loginButton2 mt-4 mb-4"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {!loading ? ("Enviar enlace" ) : (<span className="spinner-border spinner-border-sm" />)}
          </button>
        <div>
          <Link to="/" className="c-white">
            Volver al inicio de sesión
          </Link>
        </div>
      </div>
    </div>
  );
};
