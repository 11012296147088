//middleware

import { Button, Modal } from "react-bootstrap";
//utils

interface IProps {
  coordinator: string;
  center: string;
  delegates: any[];
  newCoordinatorId: number;
  handleNewCoordinator: (id: number) => void;
  handleSubmit: () => void;
  show: boolean;
  onHide: () => void;
}

export const CoordinatorModal = (props: IProps) => {
  const {
    onHide,
    center,
    coordinator,
    newCoordinatorId,
    delegates,
    handleNewCoordinator,
    handleSubmit,
  } = props;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{center}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col">
                <h6>Coordinador Actual</h6>
                <input
                  type="text"
                  className="form-control"
                  value={coordinator}
                  readOnly
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <h6>Nuevo Coordinador</h6>
                <select
                  className="form-control"
                  value={newCoordinatorId}
                  onChange={({ target: { value } }) => {
                    handleNewCoordinator(+value);
                  }}
                >
                  <option value={""}>Selecciona nuevo coordinador</option>
                  {delegates.map(({ fullName, id }) => (
                    <option key={id} value={id}>
                      {fullName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
            <Button variant="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
