import { FC } from "react";

//middleware
import { useSelector } from "react-redux";

//utils
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { ROUTES } from "../utils/routes";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//components
import { Centers } from "../views/Logged/Admin/Centers";
import { Companies } from "../views/Logged/Admin/Companies";
import { CompanyTFE } from "../views/Logged/Admin/CompanyTFE";
import { Statistics } from "../views/Logged/Admin/Statistics/Statistics";
import { ManageCompany } from "../views/Logged/Common/Company/ManageCompany";
import { ManageTFE } from "../views/Logged/Common/ManageTFE/ManageTFE";
import { UsersList } from "../views/Logged/Admin/UsersList";

export const Admin: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);

  //prettier-ignore
  return (
    <>
      <ProtectedRoute exact path={ROUTES.Admin.Company.all} component={Companies} token={token}/>
      <ProtectedRoute exact path={ROUTES.Admin.Schools.all} component={Centers} token={token}/>
      <ProtectedRoute exact path={ROUTES.Admin.Statistics} component={Statistics} token={token}/>
      <ProtectedRoute exact path={ROUTES.Admin.Users} component={UsersList} token={token}/>
      <ProtectedRoute exact path={`${ROUTES.Admin.Company.read}/:id`} component={CompanyTFE} token={token}/>  
     
      <ProtectedRoute exact path={[ROUTES.Admin.Company.create, `${ROUTES.Admin.Company.update}/:id`]} component={ManageCompany} token={token}/>
      <ProtectedRoute exact path={[ROUTES.Common.TFE.create, `${ROUTES.Common.TFE.update}/:id`]} component={ManageTFE} token={token}/>

    </>
  );
};
