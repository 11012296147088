/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//css
import "./../Sidebar.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { faSignOutAlt, faCalendar, faBell } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import axios from "axios";
import { paths } from "../../../utils/paths";
import { useEffect, useRef, useState } from "react";
import { getById } from "../../../utils/requests/dynamicReq";

interface IProps {
  logout: () => void;
}

export const StudentSidebar = (props: IProps) => {
  //prettier-ignore
  let { studentId } = useSelector(({ user }: IStore) => user as IUser);
  const { logout } = props;

  const source = axios.CancelToken.source();

  const { STUDENTS } = paths;

  const isMountedRef = useRef(false);

  const [tfeId, setTfeId] = useState();

  useEffect(() => {
    isMountedRef.current = true;
    loadStudent();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadStudent = async () => {
    try {
      if (studentId) {
        const { tfe } = await getById(STUDENTS, studentId, source);
        if (tfe && isMountedRef.current) setTfeId(tfe.id);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  return (
    <Nav className="navbar navbar-expand-md navbar-dark  align-items-start flex-md-column flex-row bg-blue ">
      <a
        href="void(0);"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#sidebarID"
      >
        <span className="navbar-toggler-icon"></span>
      </a>
      <div className="collapse navbar-collapse sidebar" id="sidebarID">
        <ul className="flex-column navbar-nav w-100 justify-content-between">
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Student.TFE.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faBell} />
                <span className="">Listado TFE</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink
                className="nav-link pl-0"
                to={`${ROUTES.Common.TFE.read}/${tfeId}`}
              >
                <FontAwesomeIcon className="iconClass mr-3" icon={faCalendar} />
                <span className="">Mi TFE</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li className="nav-item responsiveLogout" onClick={logout}>
            <a className="nav-link pl-0" href="#">
              <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
              <span className="">Cerrar sesión</span>
            </a>
          </li>
        </ul>
      </div>
    </Nav>
  );
};
