/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IStore } from "../../interfaces/IStore";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { IUser } from "../../interfaces/IUser";
import { useUpdate } from "../../utils/hooks/useUpdate";
import { paths } from "../../utils/paths";
import { getById, update } from "../../utils/requests/dynamicReq";
import { checkIfSelected } from "../../views/Logged/Common/ManageTFE/utils";

interface IProps {
  tfe: ITFEDDBB;
}

export const SelectTFE = ({ tfe }: IProps) => {
  const source = axios.CancelToken.source();
  const { id, student } = tfe;
  
  const { STUDENTS, TFE } = paths;

  //prettier-ignore
  const { studentId} = useSelector(({ user }: IStore) => user as IUser);

  const { updateIt } = useUpdate();

  const isMountedRef = useRef(false);

  const [selected, setSelected] = useState(false);
  const [selectedTfes, setSelectedTfes] = useState([]);

  useEffect(() => {
    isMountedRef.current = true;
    loadStudent();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadStudent = async () => {
    try {
      const { selectedTfes } = await getById(STUDENTS, studentId!, source);
      if (isMountedRef.current) {
        setSelected(checkIfSelected(id, selectedTfes));
        setSelectedTfes(selectedTfes);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const handleSelect = () => {
    const tfeIds: number[] = selectedTfes?.map(({ id }) => id);
    let index = tfeIds?.findIndex((idNumber) => idNumber === id);
    let selected = index === -1 ? false : true;
    if (selectedTfes && selectedTfes?.length >= 3 && !selected) {
      Swal.fire({ icon: "warning", text: "No puedes seleccionar más de 3 TFE" });
      return;
    }
    let message = "";
    if (!selected) {
      tfeIds?.push(id);
      message = "¿Quieres mostrar tu interés en este TFE?";
    }
    if (selected) {
      tfeIds?.splice(index!, 1);
      message = "¿Quieres quitar tu interés en este TFE?";
    }
    updateIt({
      path: STUDENTS,
      id: studentId!,
      message,
      body: { selectedTfeIds: tfeIds },
      source,
      reload: true,
    });

    //below updates notification status
    update(TFE, id, { notification: !selected }, source);
  };
  
  return (<>
   {!student && <button
      className={!selected ? "button-search" : "button-cancel"}
      onClick={() => handleSelect()}
    >
      {!selected ? "Mostrar interés en TFE" : "Quitar interés en TFE"}
    </button>}
    </>
  );
};
