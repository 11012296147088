import { FC } from "react";

//middleware
import { useSelector } from "react-redux";

//utils
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { ROUTES } from "../utils/routes";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//components
import { AllStudentTfes } from "../views/Logged/Student/AllStudentTfes";

export const Student: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);
  return (
    <ProtectedRoute
      exact
      path={ROUTES.Student.TFE.all}
      component={AllStudentTfes}
      token={token}
    />
  );
};
