import axios from "axios";
import { useParams } from "react-router";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { useUpdate } from "../../utils/hooks/useUpdate";
import { paths } from "../../utils/paths";

interface IProps {
  tfe: ITFEDDBB;
}

export const StudentInterest = ({ tfe }: IProps) => {
  const { selectingStudents, student: assignedStudent } = tfe;
  const source = axios.CancelToken.source();

  const { TFE } = paths;

  const { id }: any = useParams();

  const { updateIt } = useUpdate();

  const handleSubmit = (studentId: number, fullName?: string) => {
    const body = { studentId, assigned: true };
    const message = `¿Quieres asignar este TFE a "${fullName}"?`;
    updateIt({ path: TFE, id, body, source, message, reload: true });
  };

  return (
    <>
      <div className="row justify-content-center mb-4">
        <h5>Estudiantes Interesados</h5>
      </div>
      {!!!selectingStudents?.length ? (
        <div className="row justify-content-center">
          <p>No hay alumnos interesados</p>
        </div>
      ) : (
        selectingStudents.map(({ id, user }) =>
          assignedStudent?.id !== id ? (
            <div className="row mt-1" key={id}>
              <div className="col-sm-4 col-12 d-flex">
                <h6 className="mt-1 mr-2">Nombre:</h6>
                <p>{user?.fullName}</p>
              </div>
              <div className="col-sm-5 col-12 d-flex">
                <h6 className="mt-1 mr-2">Email:</h6>
                <p>{user?.email}</p>
              </div>
              <div className="col-sm-2 col-12">
                <span
                  className="pointer c-lightblue"
                  onClick={() => handleSubmit(id, user?.fullName)}
                >
                  Asignar
                </span>
              </div>
            </div>
          ) : (
            <div className="row justify-content-center">
              <p>No hay alumnos interesados</p>
            </div>
          )
        )
      )}
    </>
  );
};
