/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { statusKey } from "../../utils/constants";
import { getAll, getById } from "../../utils/requests/dynamicReq";
import axios from "axios";
import { paths } from "../../utils/paths";
import { validateUpdate } from "../../views/Logged/Common/ManageTFE/validations";
import { useParams } from "react-router";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { IUser } from "../../interfaces/IUser";
import { useUpdate } from "../../utils/hooks/useUpdate";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { role } from "../../utils/roles";
import { IDegree } from "../../interfaces/IDegree";

//utils

interface IProps {
  tfe: ITFEDDBB;
}

export const Evaluation = ({ tfe }: IProps) => {
  const { userRole } = useSelector(({ user }: IStore) => user as IUser);

  const { id }: any = useParams();
  const { TFE, CENTERS } = paths;

  const { updateIt } = useUpdate();

  const { center, degree, delegate } = tfe;

  const isMountedRef = useRef(false);
  const source = axios.CancelToken.source();

  const [delegates, setDelegates] = useState([]);
  const [degrees, setDegrees] = useState([]);

  //prettier-ignore
  const [reviewObservations, setReviewObservations] = useState(tfe.reviewObservations || "");
  const [tfeStatus, setTfeStatus] = useState(tfe.tfeStatus);
  const [delegateId, setDelegateId] = useState<number | undefined>(delegate?.id);
  const [degreeId, setDegreeId] = useState<number | undefined>(degree?.id);

  const [message, setMessage] = useState("");

  const isAdmin = userRole === role.admin;
  const toReview = tfeStatus === statusKey.toReview;
  const accepted = tfeStatus === statusKey.accepted;

  useEffect(() => {
    isMountedRef.current = true;
    delegate && loadDelegates();
    accepted && loadDegrees();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadDelegates = async () => {
    let path = `users/delegates/center/${center.id}?active=true`;
    const responseDelegates = await getAll(path, source);
    isMountedRef.current && setDelegates(responseDelegates);
  };

  const loadDegrees = async () => {
    const { degrees } = await getById(CENTERS, center.id, source);
    isMountedRef.current && setDegrees(degrees);
  };

  //--------------------------------

  const handleStatusChange = async (value: string) => {
    setReviewObservations("");
    setTfeStatus(value);
    let load = (accepted || value === statusKey.accepted) && !degrees.length;
    load && loadDegrees();
  };

  const handleSubmit = async () => {
    const tfeUpdate: any = buildToSend();

    const notValid = validateUpdate(tfeUpdate);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }

    updateIt({ path: TFE, id, body: tfeUpdate, source, reload: true });
  };

  const buildToSend = () => ({
    tfeStatus: tfeStatus || "presented",
    degreeId,
    delegateId: delegateId || null,
    reviewObservations,
  });

  return (
    <>
      <div className="row justify-content-center">
        <h5>Evaluación TFE</h5>
      </div>
      <div className="row mt-4">
        <div className="col-sm-5 col-12">
          <h6>Delegar decisión</h6>
        </div>
        <div className="col-sm-6 col-12">
          <select
            className="form-control input"
            value={delegateId}
            onChange={({ target: { value } }) => setDelegateId(+value)}
            onClick={() => !delegates.length && loadDelegates()}
          >
            <option value="">Selecciona un/a delegada/o</option>
            {delegates?.map(({ id, fullName }: IUser) => (
              <option key={id} value={id}>
                {fullName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-5 col-12">
          <h6>Selecciona el nuevo estado del TFE</h6>
        </div>
        <div className="col-sm-6 col-12">
          <select
            className="form-control input"
            value={tfeStatus}
            onChange={({ target: { value } }) => handleStatusChange(value)}
          >
            <option value="">Seleccionar estado</option>
            <option value={statusKey.accepted}>Aceptado</option>
            <option value={statusKey.toReview}>Proponer cambios</option>
            <option value={statusKey.rejected}>Rechazar</option>
            {isAdmin && (
              <option value={statusKey.signedAgreement}>Convenio firmado</option>
            )}
          </select>
        </div>
      </div>

      {toReview && (
        <>
          <div className="row mt-4">
            <div className="col-11">
              <p>*Observaciones</p>
            </div>
          </div>
          <div className="row">
            <div className="col-11">
              <textarea
                className="form-control"
                value={reviewObservations}
                onChange={({ target: { value } }) => setReviewObservations(value)}
              />
            </div>
          </div>
        </>
      )}

      {accepted && (
        <div className="row mt-4">
          <div className="col-11">
            <select
              className="form-control input"
              value={degreeId}
              onChange={({ target: { value } }) => setDegreeId(+value)}
            >
              <option>Seleccionar estudio</option>
              {degrees?.map(({ id, name }: IDegree) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-sm-11 col-12 d-flex justify-content-end ">
          <span className="text-danger">{message}</span>
          <button className="button-search" onClick={() => handleSubmit()}>
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar cambios
          </button>
        </div>
      </div>
    </>
  );
};
