import { ICompany } from "../../../../interfaces/ICompany";

export const validate = (c: ICompany) => {
      if (!c.name) return "Por favor escriba el nombre de la compañía"
      if (!c.cif) return "Por favor escriba el cif"
      if (!c.province) return "Por favor elija una provincia"
      if (!c.district) return "Por favor elija una localidad"
      if (!c.address) return "Por favor escriba una dirección"
      if (!c.postcode) return "Por favor escriba un código postal"
      if (!c.representativeFullName) return "Por favor escriba el nombre del representante"
      if (!c.representativeEmail) return "Por favor escriba el correo del representante"
      if (!c.representativePosition) return "Por favor escriba el cargo del representante"
      if (!c.representativePhone) return "Por favor escriba el teléfono del representante"
  };
  