import { ITFEDDBB } from "../../interfaces/ITFEDDBB";

interface IProps {
  tfe: ITFEDDBB;
}

export const Revisions = ({ tfe }: IProps) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <h6>Observaciones para revisar</h6>
      </div>

      <div className="form-row">
        <div className="col-12">
          <p>{tfe.reviewObservations}</p>
        </div>
      </div>
    </>
  );
};
