export const ROUTES = {
  Login: "/",
  Pivot: "/token",
  Admin: {
    Company: {
      all: "/todas/empresas",
      read: "/listado/tfes/empresa",
      create: "/crear/empresa",
      update: "/editar/empresa",
    },
    Schools: {
      all: "/centros",
    },
    Statistics: "/estadisticas",
    Users: "/usuarios",
  },
  Student: {
    TFE: {
      all: "/estudiante/todos/tfes",
    },
  },
  Company: {
    create: "/crear/empresa",
    update: "/editar/empresa",
    TFE: {
      create: "/crear/tfe",
      update: "/editar/tfe",
      all: "/todos/tfe",
      read: "/ver/tfe",
    },
  },
  Common: {
    TFE: {
      all: "/todos/tfe",
      create: "/crear/tfe",
      update: "/editar/tfe",
      read: "/ver/tfe",
    },
    Inbox: {
      all: "/buzon",
    },
    Coordinators: {
      all: "/todos/coordinadores",
      create: "/crear/coordinadores",
      update: "/actualizar/coordinadores",
    },
  },

  Not_Found: "/not-found",
  Reset_Password: "/need-new-password",
  Change_Password: "/forgot-password/company",
};
