/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//middleware
import Axios from "axios";
import { InboxList } from "../../../components/InboxList/InboxList";
import { More } from "../../../components/More/More";

//utils
import { getAll, getById } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import { ITFEDDBB } from "../../../interfaces/ITFEDDBB";
import { buildFilter } from "../Common/ManageTFE/utils";

export const CompanyTFE: FC = () => {
  const history = useHistory();
  const { id }: any = useParams();

  const { TFE, COMPANY } = paths;

  const [tfes, setTfes] = useState<ITFEDDBB[]>([]);
  const [displayTfes, setDisplayTfes] = useState<ITFEDDBB[]>([]);

  const [company, setCompany] = useState("");

  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadDefaultData = async () => {
    try {
      const filter: string = buildFilter({ userRole: "company", userId: id });
      const response = await getAll(TFE, source, filter);

      const companyResponse = await getById(COMPANY, id, source);
      setCompany(companyResponse.name);

      if (isMountedRef.current) {
        setTfes(response);
        setDisplayTfes(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12 d-flex justify-content-between">
            <h5>Tfes de {company}</h5>
          </div>
        </div>
        <div className="row">
          <div className=" ml-2">
            <button className="button-save" onClick={() => history.goBack()}>
              Atrás
            </button>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <InboxList tfes={tfes} index={index} />
            <More
              displayData={displayTfes}
              isLoading={isLoading}
              refreshIndex={setIndex}
              index={index}
              amount={6}
            />
          </div>
        </div>
      </div>
    </>
  );
};
