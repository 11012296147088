interface IProps {
  label: string;
  color: string;
}

export const BulletPoints = ({ label, color }: IProps) => {
  return (
    <div className="d-flex" style={{lineHeight: "14px", fontSize: "13px"}}>
      <div className="smallCircle mr-3" style={{ backgroundColor: color, height: "13px", width: "13px" }} />
      <p>{label}</p>
    </div>
  );
};
