/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

//css
import "./../Sidebar.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  faSignOutAlt,
  faPlusCircle,
  faUserCog,
  faList,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";

interface IProps {
  logout: () => void;
  setShowPassword: any;
}

export const CompanySidebar = ({ logout, setShowPassword }: IProps) => {
  //prettier-ignore
  let { id, profileOk, active } = useSelector(({ user }: IStore) => user as IUser);

  const isActive = profileOk && active;

  return (
    <Nav className="navbar navbar-expand-md navbar-dark  align-items-start flex-md-column flex-row bg-blue ">
      <a
        href="void(0);"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#sidebarID"
      >
        <span className="navbar-toggler-icon"></span>
      </a>
      <div className="collapse navbar-collapse sidebar" id="sidebarID">
        <ul className="flex-column navbar-nav w-100 justify-content-between">
          {isActive && (
            <>
              <li>
                <Nav.Item>
                  <NavLink className="nav-link pl-0" to={ROUTES.Company.TFE.all}>
                    <FontAwesomeIcon className="iconClass mr-3" icon={faList} />
                    <span>Mis TFE</span>
                  </NavLink>
                </Nav.Item>
              </li>
              <li>
                <Nav.Item>
                  <NavLink className="nav-link pl-0" to={ROUTES.Company.TFE.create}>
                    <FontAwesomeIcon
                      className="iconClass mr-3"
                      icon={faPlusCircle}
                    />
                    <span>Nuevo TFE</span>
                  </NavLink>
                </Nav.Item>
              </li>
            </>
          )}
          <li>
            <Nav.Item>
              <NavLink
                className="nav-link pl-0"
                to={`${ROUTES.Company.update}/${id}`}
              >
                <FontAwesomeIcon className="iconClass mr-3" icon={faUser} />
                <span>Mi Perfil</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li className="nav-item responsiveLogout" onClick={logout}>
            <a className="nav-link pl-0" href="#">
              <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
              <span>Cerrar sesión</span>
            </a>
          </li>
          <li
            className="nav-item responsiveLogout"
            onClick={() => setShowPassword(true)}
          >
            <a className="nav-link pl-0" href="#">
              <FontAwesomeIcon className="iconClass mr-3" icon={faUserCog} />
              <span>Contraseña</span>
            </a>
          </li>
        </ul>
      </div>
    </Nav>
  );
};
