/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

//middleware
import Axios from "axios";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../utils/routes";

interface IProps {
  coordinators: any[];
  index: number;
}

export const UsersTable = ({
  coordinators,
  index,
}: IProps) => {
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const history = useHistory();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Correo</th>
            <th scope="col">Centro</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {coordinators
            ?.slice(0, index)
            ?.map(({ id, fullName, email, centers}: any, i: any) => (
              <tr key={i}>
                <td>{fullName}</td>
                <td>{email}</td> 
                <td>{centers[0]?.name}</td>
                <td>
                <FontAwesomeIcon className="mr-2 pointer" color="#0099cc" icon={faEdit} 
                onClick={()=> history.push(`${ROUTES.Common.Coordinators.update}/${id}`)}/>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
