import { statusKey } from "../constants";

export const switchStatus =( status: string) =>{
    switch (status) {
      case statusKey.drafted:
        return "Borrador";
      case statusKey.presented:
        return "Presentado";
      case statusKey.accepted:
        return "Aceptado";
      case statusKey.rejected:
        return "Rechazado";
      case statusKey.toReview:
        return "En Revisión";
      case statusKey.signedAgreement:
        return "Acuerdo firmado";
      case statusKey.defended:
        return "Defendido";
      case statusKey.assigned:
        return "Asignado";
      default:
        return "";
    }
  };