import Axios from "axios";
import { store } from "../..";
import { baseURL } from "../constants";

export const login = (role: string, email: string, password: string) => {
  return Axios.post(`${baseURL}auth/${role}/login`, { email, password })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      Promise.reject(err);
      return err.response;
    });
};

export const loginIdp = () => {
  return Axios.get(`${baseURL}login-idp`)
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => {
      Promise.reject(err);
      return err.response;
    });
};

export const getAll = (path: string, source: any, filter = "", trueFalseFilter = "") => {
  return Axios.get(`${baseURL}${path}${filter}${trueFalseFilter}`, {
    cancelToken: source.token,
  })
    .then(({ status, data: { result } }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getById = (path: string, id: number, source: any, filter = "") => {
  return Axios.get(`${baseURL}${path}/${id}${filter}`, {
    cancelToken: source.token,
  })
    .then(({ status, data: { result } }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const create = (path: string, body: any, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.post(`${baseURL}${path}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  })
    .then(({ status, data: { result } }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const update = (path: string, id: number, body: any, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.put(`${baseURL}${path}/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  })
    .then(({ status, data: { result } }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const Delete = (path: string, id: number, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.delete(`${baseURL}${path}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  })
    .then(({ status, data: { result } }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};
