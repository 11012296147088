interface IProps {
  displayData: any[];
  refreshIndex(number: number): void;
  index: number;
  amount: number;
  isLoading: boolean;
  noDataMessage?: string;
}

//prettier-ignore
export const More = ({  displayData, isLoading, refreshIndex, index, amount, noDataMessage }: IProps) => {
  if(!noDataMessage)  noDataMessage=  "-No hay resultados-"

    return (
      <>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border mt-5" role="status" />
          </div>
        )}
        {displayData?.length > index && (
          <div className="d-flex justify-content-center">
            <span className="pointer" onClick={() => refreshIndex(index + amount)}>
              Ver más
            </span>
          </div>
        )}
        {!displayData?.length && !isLoading && (
          <div className=" text-center mb-3">
            <h5>{noDataMessage}</h5>
          </div>
        )}
      </>
    );
  };
