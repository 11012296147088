import { FC } from "react";

//middleware
import { useSelector } from "react-redux";

//utils
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { ROUTES } from "../utils/routes";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//components
import { TFEListComponent } from "../views/Logged/Common/ManageTFE/ListTFES/TFEListComponent";
import { ReadTFE } from "../views/Logged/Common/ManageTFE/ReadTFE";
import { Inbox } from "../views/Logged/Common/Inbox";

export const Common: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);
  //prettier-ignore
  return (
    <>
     <ProtectedRoute exact path={ROUTES.Common.TFE.all} component={TFEListComponent} token={token}/>
     <ProtectedRoute exact path={`${ROUTES.Common.TFE.read}/:id`} component={ReadTFE} token={token}/>
     <ProtectedRoute exact path={ROUTES.Common.Inbox.all} component={Inbox} token={token}/>
    </>
  );
};
