/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

//css
import "./Sidebar.css";

//utils
import { LogoutAction } from "../../redux/actions";
import logo from "../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";

//middleware
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { faGlobe, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import Swal from "sweetalert2";
import { create } from "../../utils/requests/dynamicReq";
import { SwalError } from "../../utils/constants";
import { paths } from "../../utils/paths";
import { role } from "../../utils/roles";
import { AdminSidebar } from "./SidebarComponents/AdminSidebar";
import { CompanySidebar } from "./SidebarComponents/CompanySidebar";
import { CoordinatorSidebar } from "./SidebarComponents/CoordinatorSidebar";
import { StudentSidebar } from "./SidebarComponents/Student";
import { PasswordModal } from "../Modals/PasswordModal";

interface INewPassword {
  oldPassword: string;
  newPassword: string;
}

export const Sidebar = () => {
  //prettier-ignore
  let {  userRole } = useSelector(({ user }: IStore) => user as IUser);

  const dispatch = useDispatch();
  const history = useHistory();
  const { NEWPASSWORD } = paths;

  const [loading, setLoading] = useState(false);

  //change Password
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPass, setRepeatNewPass] = useState("");
  const [showPassword, setShowPassword] = useState<any>(false);

  const source = Axios.CancelToken.source();

  //----------------
  //HANDLE CAHNGE PASSWORD

  const handleSubmitNewPassword = () => {
    const newPasswordForm: INewPassword = {
      oldPassword,
      newPassword,
    };
    Swal.fire({
      icon: "question",
      text: `¿Quieres cambiar tu contraseña?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleNewPasswordConfirmation(newPasswordForm);
    });
  };

  const handleNewPasswordConfirmation = (newPasswordForm: INewPassword) => {
    create(NEWPASSWORD, newPasswordForm, source)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Contraseña cambiada correctamente.`,
        });
        setNewPassword("");
        setOldPassword("");
        setRepeatNewPass("");
        setShowPassword(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  //----------------
  const logout = () => {
    setLoading(true);
    dispatch(LogoutAction());
    sessionStorage.removeItem("token");
    history.push("/");
  };

  const admin = userRole === role.admin;

  const company = userRole === role.company;
  const coordinator = userRole === role.cordinator;
  const delegate = userRole === role.delegate;
  const student = userRole === role.student;

  return (
    <>
      <aside className="col-12 col-md-2 p-0  flex-shrink-1 bg-blue  centerPage">
        <div className="sidebar-heading mt-4 ml-2">
          <img className="sidebarLogo ml-4" src={logo} alt="" />
        </div>
        <div className="sidebar-heading mt-3 whiteHeader ">
          <h6 className=" sidebarText">Impulso TFE</h6>
        </div>

        {admin && <AdminSidebar logout={logout} />}
        {(coordinator || delegate) && <CoordinatorSidebar logout={logout} />}
        {student && <StudentSidebar logout={logout} />}
        {company && (
          <CompanySidebar logout={logout} setShowPassword={setShowPassword} />
        )}

        <div className="container webButton">
          <div className="row ">
            <div
              className="col-12 pointer"
              onClick={() => (window.location.href = "https://impulsotfe.uma.es")}
            >
              <FontAwesomeIcon className="mt-1 ml-3 mr-3" icon={faGlobe} />
              <span>Web ImpulsoTFE</span>
            </div>
          </div>
        </div>
        <div className="container logoutButton">
          <div className="row mt-2">
            <div className="col-12 pointer" onClick={logout}>
              <FontAwesomeIcon className="mt-1 ml-3 mr-3" icon={faSignOutAlt} />
              {!loading ? (
                <span className="d-none d-md-inline mr-2">Cerrar sesión</span>
              ) : (
                <span className="spinner-border spinner-border-sm" />
              )}
            </div>
          </div>
        </div>
      </aside>
      <PasswordModal
        show={showPassword}
        onHide={() => setShowPassword(false)}
        oldPassword={oldPassword}
        setOldPassword={setOldPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        repeatNewPass={repeatNewPass}
        setRepeatNewPass={setRepeatNewPass}
        handleSubmitNewPassword={handleSubmitNewPassword}
      />
    </>
  );
};
