/* eslint-disable react-hooks/exhaustive-deps */
import { verify } from "jsonwebtoken";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { SetUserAction } from "../../redux/actions";
import { jwtPassword, tokenNotFound } from "../../utils/constants";
import { role } from "../../utils/roles";
import { ROUTES } from "../../utils/routes";

export const Pivot: FC = () => {
  const { token }: any = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    token === tokenNotFound ? notAllowed() : decodeToken();
  }, [token]);

  const decodeToken = () => {
    try {
      if (token) {
        const user: any = verify(token, jwtPassword);
        setToken(user);
        redirectUser(user);
        setIsLoading(false);
      }
    } catch (error) {
      //if token was fiddled with
      setIsLoading(false);
      sessionStorage.removeItem("token");
      history.push("/");
    }
  };

  const setToken = (user: any) => {
    sessionStorage.setItem("token", token);

    const { id, email, fullName, role, studentId, degreeId } = user;
    dispatch(
      SetUserAction({
        token,
        id,
        studentId: studentId || undefined,
        userRole: role,
        isAdmin: role === role.admin,
        fullName,
        email,
        degreeId,
      })
    );
  };

  const redirectUser = ({ role: userRole }: any) => {
    switch (userRole) {
      case role.admin:
      case role.cordinator:
      case role.delegate:
        history.push(ROUTES.Common.Inbox.all);
        break;
      case role.student:
        history.push(ROUTES.Student.TFE.all);
        break;
    }
  };

  const notAllowed = () => {
    Swal.fire({
      icon: "warning",
      allowOutsideClick: false,
      allowEscapeKey: false,
      text: `Tus credenciales UMA no tienen acceso a Impulso TFE. De ser incorrecto, contacta con tu administrador en la dirección de correo impulsotfe@uma.es`,
    }).then(({ isConfirmed }) => isConfirmed && history.push(ROUTES.Login));
  };

  return (
    <>
      <div className="fullWidth fullHeight center">
        {isLoading && (
          <span
            className="spinner-grow"
            style={{ width: "5rem", height: "5rem", backgroundColor: "#cd176f" }}
          />
        )}
      </div>
    </>
  );
};
