/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

import logo from "../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";
import { paths } from "../../utils/paths";
import { getAll, update } from "../../utils/requests/dynamicReq";
import Axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { IDegree } from "../../interfaces/IDegree";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { SwalError } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { LogoutAction } from "../../redux/actions";

const style = {
  input: { width: "100%", height: "2.5rem", borderRadius: "5px" },
};

export const ChooseDegree: FC = () => {
  let { studentId } = useSelector(({ user }: IStore) => user as IUser);

  const { DEGREES, STUDENTS } = paths;

  const dispatch = useDispatch();
  const history = useHistory();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [degrees, setDegrees] = useState<IDegree[]>([]);
  const [degree, setDegree] = useState<IDegree>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    loadDegrees();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const loadDegrees = async () => {
    const resDegrees = await getAll(DEGREES, source);
    isMountedRef.current && setDegrees(resDegrees);
  };

  const handleSubmit = () => {
    Swal.fire({
      icon: "question",
      html: `¿Quieres darte de alta bajo el <strong>${degree?.name}</strong>? Una vez registrado no podrás cambiar este estudio.`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirm();
    });
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    const body = { degreeId: degree?.id };
    update(STUDENTS, studentId!, body, source)
      .then(() => {
        logout();
        Swal.fire({
          allowOutsideClick: false,
          icon: "success",
          text: "¡Gracias por registrarte! Tus estudios han sido actualizados correctamente. Por favor vuelve a logearte.",
        });
      })
      .catch(() => {
        setIsLoading(false);
        Swal.fire({ icon: "error", text: SwalError });
      });
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    setTimeout(() => {
      dispatch(LogoutAction());
      history.push("/");
    }, 500);
  };

  return (
    <div className="resetPasswordContainer">
      <div className="cardResetPassword">
        <img className="loginLogo mb-2" src={logo} alt="" />
        <h5>Gracias por registrarte en Impulso TFE</h5>
        <p className="textCardPassword">
          Para poder acceder a nuestra web te pedimos nos digas tus estudios
        </p>
        <div className="container-fluid text-left">
          <div className="row">
            <div className="col-12">
              <small>Estudios</small>
              <Autocomplete
                options={degrees}
                getOptionLabel={({ name }) => name}
                onChange={(e, value) => setDegree(value!)}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input style={style.input} type="text" {...params.inputProps} />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <button
          style={{ cursor: !degree ? "not-allowed" : "" }}
          className="loginButton2 mt-4 mb-4"
          disabled={!degree}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Entrar en Impulso TFE"
          )}
        </button>
        <div>
          {/* <Link to="/" className="c-white">
          Volver al inicio de sesión
        </Link> */}
        </div>
      </div>
    </div>
  );
};
