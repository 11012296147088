/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";


//middleware
import Axios from "axios";

//utils
import { CenterList } from "../../../components/CenterList/CenterList";
import { ICenter } from "../../../interfaces/ICenter";
import { paths } from "../../../utils/paths";
import { getAll, update } from "../../../utils/requests/dynamicReq";
import Swal from "sweetalert2";
import { SwalError } from "../../../utils/constants";



export const Centers: FC = () => {
  const { CENTERS } = paths;
 
  const [centers, setCenters] = useState<ICenter[]>([]);
  
  //Filters
  const [isLoading, setIsLoading] = useState(false);
  
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    setIsLoading(true)
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadDefaultData = async () => {
    try {
      const response = await getAll(CENTERS, source);
      if (isMountedRef.current) {
        setIsLoading(false)
        setCenters(response);
      }
    } catch (error) {
      setIsLoading(false)
      console.error("HTTP call cancelled");
    }
  };


  const updateCenter = (centerId: number) => {
    let updatedCenter = centers.filter((c) => c.id === centerId);
    let center = buildCenter(updatedCenter);

    handleSubmit(center, centerId);
  };

  const handleSubmit = (buildCentre: any, centreId: number) => {
    update(CENTERS, centreId, buildCentre, source)
      .then(async () => {
        Swal.fire({
          icon: "success",
          text: `Estado del TFE cambiado correctamente.`,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const buildCenter = (updatedTfe: ICenter[]) => ({
    allowsAgreements: updatedTfe[0]?.allowsAgreements,
    allowsSelection: updatedTfe[0]?.allowsSelection,
  });



  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h5>Centros</h5>
          </div>
        </div>
        
        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <CenterList centers={centers}  setCenters={setCenters} updateCenter={updateCenter} />
            {isLoading && (
             <div className="d-flex justify-content-center">
               <div className="spinner-border" role="status" />
             </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};