import { ITFEDDBB } from "../../interfaces/ITFEDDBB";

interface IProps {
  tfe?: ITFEDDBB;
}

export const CompanyTutor = ({ tfe }: IProps) => {
  return (
    <>
      <div className="row justify-content-center">
        <h5>Información tutor de la empresa</h5>
      </div>
      <div className="row mt-4">
        <div className="col-sm-2 col-12">
          <h6>Nombre:</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{tfe?.companyTutorFullName}</p>
        </div>
        <div className="col-sm-2 col-12">
          <h6>Email:</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{tfe?.companyTutorEmail}</p>
        </div>
      </div>
    </>
  );
};
