/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//middleware
import Axios from "axios";
import { More } from "../../../components/More/More";
import { ICompany } from "../../../interfaces/ICompany";
import { getAll, update } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import { CompaniesTable } from "../../../components/Companies/CompaniesTable";
import { normalizer } from "../../../utils/auxiliar/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";
import Swal from "sweetalert2";
import { SwalError } from "../../../utils/constants";
import { activeFilter } from "../Common/ManageTFE/utils";

//utils

export const Companies: FC = () => {
  const { COMPANY } = paths;

  const history = useHistory();

  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [displayCompanies, setDisplayCompanies] = useState<ICompany[]>([]);
  const [index, setIndex] = useState(50);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const trueFalseFilter: string = activeFilter({
        active: "profileOk",
        option: true,
      });
      const responseCompanies = await getAll(COMPANY, source, trueFalseFilter);

      if (isMountedRef.current) {
        setIsLoading(false);
        setCompanies(responseCompanies);
        setDisplayCompanies(responseCompanies?.slice(0, index));
      }
    } catch (error) {
      isMountedRef.current && setIsLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  const refreshIndex = (number: number) => {
    setIndex(number);
    setDisplayCompanies(companies?.slice(0, number));
  };

  const filter = (value: any) => {
    !value && setDisplayCompanies(companies);
    value = normalizer(value.toLowerCase());

    setDisplayCompanies(
      companies.filter(
        ({ name, cif, address, province, district, email }: ICompany) => {
          return (
            (name && normalizer(name).includes(value)) ||
            (cif && normalizer(cif).includes(value)) ||
            (address && normalizer(address).includes(value)) ||
            (district && normalizer(district).includes(value)) ||
            (email && normalizer(email).includes(value)) ||
            (province && normalizer(province).includes(value))
          );
        }
      )
    );
  };

  const updateCompany = (companyId: number, message: string) => {
    let updatedCompany = companies.filter((c) => c.id === companyId);
    let center = buildCenter(updatedCompany);

    handleSubmit(center, companyId, message);
  };

  const handleSubmit = (buildCentre: any, centreId: number, message: string) => {
    update(COMPANY, centreId, buildCentre, source)
      .then(() =>
        Swal.fire({ icon: "success", text: `Empresa ${message} correctamente.` })
      )
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };

  const buildCenter = (updatedTfe: ICompany[]) => ({
    active: updatedTfe[0]?.active,
  });

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-11 d-flex justify-content-between">
            <h5>Empresas</h5>
            <div style={{ width: "150px " }}>
              <button
                className="button-search"
                onClick={() => history.push(ROUTES.Admin.Company.create)}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlusCircle} />
                Nueva empresa
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-12">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar..."
              onChange={({ target: { value } }) => filter(value)}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-11 col-12 whiteDiv overFlowAuto">
            <CompaniesTable
              companies={displayCompanies}
              index={index}
              setCompanies={setCompanies}
              updateCompany={updateCompany}
            />
            <More
              displayData={displayCompanies}
              isLoading={isLoading}
              refreshIndex={refreshIndex}
              index={index}
              amount={20}
            />
          </div>
        </div>
      </div>
    </>
  );
};
