//middleware
import moment from "moment";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";
import { Form } from "react-bootstrap";
import { ITFEDDBB } from "../../interfaces/ITFEDDBB";
import { ReadOrEdit } from "./ReadOrEdit";

//utils
import { role } from "../../utils/roles";
import { dateForTFE } from "../../utils/auxiliar/dates";
import { switchStatus } from "../../utils/auxiliar/switchs";
import { statusDisplayForCompany } from "./utils";

interface IProps {
  tfes: ITFEDDBB[];
  index: number;
  setTfes: any;
  updateTfe: (tfeId: number) => void;
}

export const TFEsTable = ({ tfes, index, setTfes, updateTfe }: IProps) => {
  let { userRole } = useSelector(({ user }: IStore) => user as IUser);

  const settingActiveToggle = (i: any) => {
    // eslint-disable-next-line eqeqeq
    if (tfes[i].active == false) {
      let tfeId = tfes[i].id;
      let newTfe = [...tfes];
      newTfe[i].active = true;
      setTfes(newTfe);
      setTimeout(() => {
        updateTfe(tfeId!);
      }, 200);
      // eslint-disable-next-line eqeqeq
    } else if (tfes[i].active == true) {
      let tfeId = tfes[i].id;
      let newTfe = [...tfes];
      newTfe[i].active = false;
      setTfes(newTfe);
      setTimeout(() => {
        updateTfe(tfeId!);
      }, 200);
    }
  };

  const isCompany = true ? userRole === role.company : false;
  const isAdmin = true ? userRole === role.admin : false;

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Código TFE</th>
            <th scope="col">Tipo</th>
            <th scope="col">Fecha de propuesta</th>
            {!isCompany && <th scope="col">Empresa</th>}
            <th scope="col">Título</th>
            <th scope="col">Estado</th>
            {isAdmin && <th scope="col">Activo</th>}
            <th scope="col"> </th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {tfes
            ?.slice(0, index)
            ?.map(
              (
                {
                  id,
                  createdAt,
                  tfeType,
                  title,
                  tfeStatus,
                  company,
                  active,
                  notification,
                  delegate,
                }: ITFEDDBB,
                i: any
              ) => (
                <tr key={i}>
                  <td className="d-flex">
                    {notification && !isCompany && <div className="smallCircle" />}
                    <strong>{`#${moment().year()}-${id}`} </strong>
                  </td>
                  <td>{tfeType.toLocaleUpperCase()}</td>
                  <td>{dateForTFE(createdAt!)}</td>
                  {!isCompany && <td>{company?.name}</td>}
                  <td>{title}</td>
                  <td>
                    {isCompany
                      ? statusDisplayForCompany(tfeStatus)
                      : switchStatus(tfeStatus)}
                  </td>
                  {isAdmin && (
                    <td>
                      <Form>
                        <Form.Check
                          type="switch"
                          id={i + 1}
                          checked={active}
                          onChange={() => settingActiveToggle(i)}
                        />
                      </Form>
                    </td>
                  )}
                  <td>
                    <ReadOrEdit tfeStatus={tfeStatus} id={id} delegate={delegate} />
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </>
  );
};
