export const normalizer = (() => {
  let from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
    to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
    mapping = {};

  for (let i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return (str) => {
    let ret = [];
    for (let i = 0, j = str.length; i < j; i++) {
      let c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join("").toLowerCase();
  };
})();

export const stringShortener = (content, number) => {
  let wordCount = content?.split(" ").length;
  let reducedString = "";
  wordCount > number
    ? (reducedString = content.split(" ").slice(0, number).join(" ") + "...")
    : (reducedString = content);

  return reducedString;
};

export const parseTfeType = (tfe) => {
  switch (tfe) {
    case "tfg":
      return "Trabajo de Fin de Grado";
    case "tfm":
      return "Trabajo de Fin de Masters";
    default:
      return "";
  }
};
