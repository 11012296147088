import { centerColorIndex } from "../../../utils/constants";
import { BulletPoints } from "../BulletPoints";

export const CenterIndex = () => {
  return (
    <>
      <h6 className="mb-3">Centros</h6>
      {centerColorIndex.map(({ name, color }, i) => (
        <BulletPoints key={i} label={name} color={color} />
      ))}
    </>
  );
};
