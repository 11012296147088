import { FC } from "react";

//middleware
import { useSelector } from "react-redux";

//utils
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { ROUTES } from "../utils/routes";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//components
import { ManageCoordinators } from "../views/Logged/Common/ManageCoordinators/ManageCoordinators";
import { CoordinationList } from "../views/Logged/Common/ManageCoordinators/CoordinationList";

export const Coordinator: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);
  //prettier-ignore
  return (
    <>
    <ProtectedRoute exact path={ROUTES.Common.Coordinators.all} component={CoordinationList} token={token}/>
    <ProtectedRoute exact path={[ROUTES.Common.Coordinators.create, `${ROUTES.Common.Coordinators.update}/:id`]} component={ManageCoordinators} token={token}/>
  </>
  );
};
