/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//middleware
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

//req
import {
  create,
  getAll,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";

import { StatusBadge } from "../../../../components/StatusBadge/StatusBadge";

//interfaces
import { ITFEDDBB } from "../../../../interfaces/ITFEDDBB";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";
import { ITFE } from "../../../../interfaces/ITFE";

//utils
import { buildAnswer, buildQuestion, setDisabledInputs } from "./utils";
import { statusKey, SwalError } from "../../../../utils/constants";
import { paths } from "../../../../utils/paths";
import { ROUTES } from "../../../../utils/routes";
import { validateTFE } from "./validations";
import { role } from "../../../../utils/roles";
import { dateToDDBB } from "../../../../utils/auxiliar/dates";
import { ICenter } from "../../../../interfaces/ICenter";

export const ManageTFE: FC = () => {
  const { COMPANY, TFE, CENTERS } = paths;
  const { id: userId, userRole } = useSelector(({ user }: IStore) => user as IUser);

  const history = useHistory();
  const { id }: any = useParams();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const isAdmin = userRole === role.admin;

  const [isCreate, setIsCreate] = useState(false);

  const [tfeType, setTfeType] = useState<"tfg" | "tfm">();
  const [centers, setCenters] = useState([]);
  const [centerId, setCenterId] = useState<number>();
  const [companyId, setCompanyId] = useState<number>();
  const [companies, setCompanies] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [objectives, setObjectives] = useState("");
  const [observations, setObservations] = useState<string | undefined>("");
  const [companyTutorFullName, setCompanyTutorFullName] = useState("");
  const [companyTutorEmail, setCompanyTutorEmail] = useState("");
  const [studentAgreement, setStudentAgreement] = useState<0 | 1>(0);
  //prettier-ignore
  const [agreementStudentFullName, setAgreementStudentFullName] = useState<string | undefined>();
  //prettier-ignore
  const [agreementStudentEmail, setAgreementStudentEmail] = useState<string | undefined>();
  const [tutorAgreement, setTutorAgreement] = useState<0 | 1>(0);
  const [umaTutorFullName, setUmaTutorFullName] = useState<string | undefined>();
  const [umaTutorEmail, setUmaTutorEmail] = useState<string | undefined>();
  const [tfeStatus, setTfeStatus] = useState(statusKey.drafted);
  const [reviewObservations, setReviewObservations] = useState<string | undefined>(
    ""
  );
  const [presentedDate, setPresentedDate] = useState<Date | undefined>();
  const [allowsAgreements, setAllowsAgreements] = useState(false);

  const [reload, setReload] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname }: any = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadEditData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [isCreate, reload]);

  const loadEditData = async () => {
    try {
      loadCenters();
      let tfe = await getById(TFE, id, source);
      unmountIfNotMyTFE(tfe);
      isMountedRef.current && build(tfe);
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const loadCenters = async () => {
    try {
      if (!centers.length) {
        const responseCenters = await getAll(CENTERS, source);
        isMountedRef.current && setCenters(responseCenters);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const loadCompanies = async () => {
    try {
      if (!companies.length) {
        let path = `${COMPANY}?active=true`;
        const responseCompanies = await getAll(path, source);
        isMountedRef.current && setCompanies(responseCompanies);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const unmountIfNotMyTFE = ({ company }: ITFEDDBB) => {
    if (userRole === role.company && userId !== company.id)
      history.push(ROUTES.Common.TFE.all);
  };

  const build = (tfe: ITFEDDBB) => {
    setCompanyId(tfe.company.id);
    setCenterId(tfe.center.id);
    setTitle(tfe.title);
    setDescription(tfe.description);
    setObjectives(tfe.objectives);
    setObservations(tfe.observations);
    setCompanyTutorFullName(tfe.companyTutorFullName);
    setCompanyTutorEmail(tfe.companyTutorEmail);
    setStudentAgreement(tfe.studentAgreement ? 1 : 0);
    setAgreementStudentFullName(tfe.agreementStudentFullName);
    setAgreementStudentEmail(tfe.agreementStudentEmail);
    setTutorAgreement(tfe.tutorAgreement ? 1 : 0);
    setUmaTutorFullName(tfe.umaTutorFullName);
    setUmaTutorEmail(tfe.umaTutorEmail);
    setTfeStatus(tfe.tfeStatus);
    setTfeType(tfe.tfeType);
    setReviewObservations(tfe.reviewObservations);
    setPresentedDate(tfe.presentedDate);
    setAllowsAgreements(tfe.center.allowsAgreements);
  };

  //--------------------------------

  const handleSubmit = ({ status }: { status: string }) => {
    const tfe: ITFE = buildToSend(status);
    const notValid = validateTFE(tfe);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = buildQuestion(isCreate, status);
    Swal.fire({
      icon: "question",
      text: question,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => isConfirmed && handleConfirmation(tfe, status));
  };

  const handleConfirmation = (tfe: ITFE, status: string) => {
    let createOrUpdate = isCreate
      ? create(TFE, tfe, source)
      : update(TFE, id, tfe, source);
    createOrUpdate
      .then(async (res) => {
        let answer = buildAnswer(isCreate, status);
        Swal.fire({ icon: "success", text: answer });

        history.push(`${ROUTES.Common.TFE.update}/${res.id}`);
        setReload(!reload);
        isCreate && isMountedRef.current && setIsCreate(false);
      })
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };

  const buildToSend = (draftStatus: string) => ({
    tfeType: tfeType!,
    centerId: centerId!,
    companyId: isAdmin ? companyId! : userId!,
    userId,
    title,
    description,
    objectives,
    observations,
    companyTutorFullName,
    companyTutorEmail,
    umaTutorFullName,
    umaTutorEmail,
    studentAgreement,
    agreementStudentFullName,
    agreementStudentEmail,
    tutorAgreement,
    reviewObservations,
    tfeStatus: draftStatus,
    presentedDate: presentedDate || dateToDDBB(new Date()),
  });

  const disabled = setDisabledInputs(tfeStatus);

  const handleSelectCenter = (value: number) => {
    setCenterId(+value);

    if (value) {
      //prettier-ignore
      let [{ allowsAgreements }]: ICenter[] = centers.filter(({ id }) => id === value);
      setAllowsAgreements(allowsAgreements);
    } else setAllowsAgreements(false);
  };

  const toReview =
    tfeStatus === statusKey.toReview || tfeStatus === statusKey.rejected;

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-2">
          <div className="col-11 ">
            <h5>Nueva propuesta de TFE</h5>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-11 col-12 whiteDiv">
            <div className="row justify-content-end">
              <div className="col-sm-8 col-12 d-flex justify-content-end">
                <h5>Información general del TFE</h5>
              </div>
              <div className="col-sm-4 col-12 d-flex justify-content-end">
                <StatusBadge status={tfeStatus} />
              </div>
            </div>
            <div className="form-row">
              <p>*Tipo de Trabajo Fin de Estudios</p>
            </div>

            <div className="form-row">
              <div className="col-sm-4 col-12">
                <label>
                  <input
                    className="mr-2"
                    value="tfg"
                    checked={tfeType === "tfg"}
                    onChange={() => setTfeType("tfg")}
                    type="radio"
                    disabled={disabled}
                  />
                  Trabajo de fin de grado (TFG)
                </label>
              </div>
              <div className="col-sm-4 col-12">
                <label>
                  <input
                    className="mr-2"
                    value="tfm"
                    checked={tfeType === "tfm"}
                    onChange={() => setTfeType("tfm")}
                    type="radio"
                    disabled={disabled}
                  />
                  Trabajo de fin de Máster (TFM)
                </label>
              </div>
            </div>
            {isAdmin && (
              <div className="form-row mt-2">
                <div className="col-sm-8 col-12">
                  <small>*Empresa</small>
                  <select
                    className="form-control input"
                    value={companyId}
                    onChange={({ target: { value } }) => setCompanyId(+value)}
                    onClick={() => loadCompanies()}
                    disabled={disabled}
                  >
                    <option>Selecciona una empresa</option>
                    {companies?.map(({ id, name }: any) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="form-row mt-4">
              <small>*Centro al que se dirige el TFE</small>
            </div>
            <div className="form-row">
              <div className="col-sm-8 col-12">
                <select
                  className="form-control input"
                  value={centerId}
                  onChange={({ target: { value } }) => handleSelectCenter(+value)}
                  disabled={disabled}
                  onClick={() => loadCenters()}
                >
                  <option value="">Selecciona un centro</option>
                  {centers?.map(({ id, name }: ICenter) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row mt-4">
              <small>*Título</small>
            </div>
            <div className="form-row">
              <div className="col-11">
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={({ target: { value } }) => setTitle(value)}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="form-row mt-4">
              <small>*Descripción</small>
            </div>
            <div className="form-row">
              <div className="col-11">
                <textarea
                  className="form-control"
                  value={description}
                  onChange={({ target: { value } }) => setDescription(value)}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="form-row mt-4">
              <small>Objetivos (Opcional)</small>
            </div>
            <div className="form-row">
              <div className="col-11">
                <textarea
                  className="form-control"
                  value={objectives}
                  onChange={({ target: { value } }) => setObjectives(value)}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="form-row mt-4">
              <small>Observaciones (Opcional)</small>
            </div>
            <div className="form-row">
              <div className="col-11">
                <textarea
                  className="form-control"
                  value={observations}
                  onChange={({ target: { value } }) => setObservations(value)}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-11 col-12 whiteDiv">
            <div className="d-flex justify-content-center">
              <h6>Información de tutor/a de la empresa</h6>
            </div>

            <div className="form-row">
              <div className="col-sm-6 col-12">
                <small>*Nombre completo</small>
                <input
                  type="text"
                  className="form-control"
                  value={companyTutorFullName}
                  onChange={({ target: { value } }) =>
                    setCompanyTutorFullName(value)
                  }
                  disabled={disabled}
                />
              </div>
              <div className="col-sm-6 col-12">
                <small>*Correo electrónico</small>
                <input
                  type="text"
                  className="form-control"
                  value={companyTutorEmail}
                  onChange={({ target: { value } }) => setCompanyTutorEmail(value)}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
        {allowsAgreements && (
          <div className="row mt-4">
            <div className="col-sm-11 col-12 whiteDiv">
              <div className="d-flex justify-content-center">
                <h6>Acuerdos existentes</h6>
              </div>
              <div className="form-row">
                <div className="col-sm-4 col-12">
                  ¿Existe acuerdo con estudiante?
                </div>
                <div className="col-sm-2 col-12">
                  <label>
                    <input
                      className="ml-4 mr-2"
                      value={1}
                      checked={studentAgreement === 1}
                      onChange={() => setStudentAgreement(1)}
                      type="radio"
                      disabled={disabled}
                    />
                    Si
                  </label>
                  <label>
                    <input
                      className="ml-4 mr-2"
                      checked={studentAgreement === 0}
                      onChange={() => {
                        setStudentAgreement(0);
                        setAgreementStudentFullName("");
                        setAgreementStudentEmail("");
                      }}
                      type="radio"
                      disabled={disabled}
                    />
                    No
                  </label>
                </div>
              </div>

              {!!studentAgreement && (
                <div className="form-row mt-1">
                  <div className="col-sm-6 col-12">
                    <small>Nombre completo del estudiante</small>
                    <input
                      type="text"
                      className="form-control"
                      value={agreementStudentFullName}
                      onChange={({ target: { value } }) =>
                        setAgreementStudentFullName(value)
                      }
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <small>Email</small>
                    <input
                      type="text"
                      className="form-control"
                      value={agreementStudentEmail}
                      onChange={({ target: { value } }) =>
                        setAgreementStudentEmail(value)
                      }
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}

              <div className="form-row mt-4">
                <div className="col-sm-4 col-12">¿Existe acuerdo con un tutor?</div>
                <div className="col-sm-2 col-12">
                  <label>
                    <input
                      className="ml-4 mr-2"
                      value="tfg"
                      checked={tutorAgreement === 1}
                      onChange={() => setTutorAgreement(1)}
                      type="radio"
                      disabled={disabled}
                    />
                    Si
                  </label>
                  <label>
                    <input
                      className="ml-4 mr-2"
                      value="tfg"
                      checked={tutorAgreement === 0}
                      onChange={() => {
                        setUmaTutorFullName("");
                        setUmaTutorEmail("");
                        setTutorAgreement(0);
                      }}
                      type="radio"
                      disabled={disabled}
                    />
                    No
                  </label>
                </div>
              </div>

              {!!tutorAgreement && (
                <div className="form-row mt-1">
                  <div className="col-sm-6 col-12">
                    <small>Nombre completo</small>
                    <input
                      type="text"
                      className="form-control"
                      value={umaTutorFullName}
                      onChange={({ target: { value } }) =>
                        setUmaTutorFullName(value)
                      }
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <small>Email</small>
                    <input
                      type="text"
                      className="form-control"
                      value={umaTutorEmail}
                      onChange={({ target: { value } }) => setUmaTutorEmail(value)}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {toReview && (
          <div className="row mt-4">
            <div
              className="col-sm-11 col-12 whiteDiv"
              style={{ border: "2px solid red" }}
            >
              <div className="d-flex justify-content-center">
                <h6>Observaciones para revisar</h6>
              </div>

              <div className="form-row">
                <div className="col-12">
                  <p>{reviewObservations}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {(tfeStatus === statusKey.drafted || tfeStatus === statusKey.toReview) && (
          <div className="row mt-2">
            <div className="col-sm-11 col-12 d-flex justify-content-end ">
              <span className="text-danger">{message}</span>

             {!isAdmin && <button
                className="button-search"
                onClick={() => handleSubmit({ status: statusKey.drafted })}
              >
                <FontAwesomeIcon className="mr-2" icon={faSave} />
                Guardar como borrador
              </button>}
              <button
                className="button-search"
                onClick={() => handleSubmit({ status: statusKey.presented })}
              >
                Presentar
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
