import { FC } from "react";
import { HeaderTFE } from "./HeaderTFE";
import { ListTFE } from "./ListTFE";

export const TFEListComponent: FC = () => {
    return (
      <>
      <div className="container-fluid pl-4">
       <HeaderTFE/>
       <ListTFE/>
       </div>
      </>
    );
  };