export const paths = {
  USERS: "users",
  CENTERS: "centers",
  DEGREES: "degrees",
  COMPANY: "companies",
  TFE: "tfes",
  LOGS: "logs/tfe",
  STUDENTS: "students",
  INBOX: "tfes/inbox",
  USERSBYROLE: "users/role",
  DELEGATESBYCENTER: "users/delegates/center",
  STATISTICS: "tfes/statistics",
  NEWPASSWORD: "auth/change-password",
  FORGOTPSS: "auth/company/forgot-password"
};
