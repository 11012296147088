import { FC, useState } from "react";

//css
import "./AccessWeb.css";

//images
import logo from "../../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";
import impulso from "../../../assets/images/ImpulsoLogin.png";

//aux
import { Login } from "../../../components/Login/Login";
import { Register } from "../../../components/Register/Register";

export const AccessWeb: FC = () => {
  const [isLogin, setisLogin] = useState(true);

  return (
    <div className="loginContainer">
      <div className="divImpulsoLogo">
        <img className="loginLogo" src={impulso} alt="logo" />
      </div>
      {/* prettier-ignore */}
      <div className="cardLogin" >
          <img className="loginLogo mb-2" src={logo} alt="" />
          {isLogin ? <Login setIsLogin={setisLogin} /> : <Register setIsLogin={setisLogin}/>}
        </div>
    </div>
  );
};
