import { TAction } from "../types";
import { IUser } from "../../interfaces/IUser";
import produce from "immer";

// export const MOCKtoken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImVtcHJlc2FAZ21haWwuY29tIiwiaWQiOjY4LCJuYW1lIjoiRW1wcmVzYSIsInByb2ZpbGVPayI6dHJ1ZSwicm9sZSI6ImNvbXBhbnkiLCJpYXQiOjE2MjA2Nzc1MjF9.LftVCubd4LgGV-toB9ACBSTU_l9071CGR9_ea-JlbIo";

// const coordinatorToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RpdmUiOnRydWUsImVtYWlsIjoibWFydGluQG1hcnRpbi5jb20iLCJpZCI6MiwibmFtZSI6Im1hcnRpbiIsInByb2ZpbGVPayI6dHJ1ZSwicm9sZSI6ImNvb3JkaW5hdG9yIiwiaWF0IjoxNjIxOTA0Nzc3fQ.5eB1NLsXl5XY2Tp-b3vibQXY6UGThXO-zW74H7ZX-7s";
// const adminToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RpdmUiOnRydWUsImVtYWlsIjoibWFydGluQG1hcnRpbi5jb20iLCJpZCI6MSwibmFtZSI6Im1hcnRpbiIsInByb2ZpbGVPayI6dHJ1ZSwicm9sZSI6ImFkbWluIiwiaWF0IjoxNjIxOTA0Nzc3fQ.HWeiZxC9P7-n81mnfJFLFzAGMU1EBds0IAxi23bCooU";

const initialState: IUser = {
  id: 0,
  token: "",
  fullName: "",
  email: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: IUser = initialState, action: TAction): IUser =>
  produce(state, () => {
    switch (action.type) {
      case "SET_USER":
        return action.payload;
      case "UNSET_USER":
        return initialState;
      case "LOGOUT":
        return initialState;
      default:
        return state;
    }
  });
