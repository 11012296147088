/* eslint-disable array-callback-return */
import { ITFEDDBB } from "../../../../interfaces/ITFEDDBB";
import { datetimeToDDBB } from "../../../../utils/auxiliar/dates";
import { statusKey } from "../../../../utils/constants";
import { role } from "../../../../utils/roles";

export const buildQuestion = (isCreate: boolean, status: string) => {
  if (isCreate) return "¿Quieres crear un TFE?";
  if (status === "drafted") return "¿Quieres guardar este TFE?";
  if (status === "presented") return "¿Quieres presentar este TFE?";
};

export const buildAnswer = (isCreate: boolean, status: string) => {
  if (isCreate) return "TFE creado correctamente.";
  if (status === "drafted") return "TFE editado correctamente";
  if (status === "presented") return "TFE presentado correctamente";
};

export const setDisabledInputs = (tfeStatus: string) =>
  tfeStatus !== statusKey.drafted && tfeStatus !== statusKey.toReview;

export const setAccepted = (tfeStatus: string) =>
  tfeStatus === statusKey.accepted ||
  tfeStatus === statusKey.assigned ||
  tfeStatus === statusKey.defended ||
  tfeStatus === statusKey.signedAgreement;

export const setEditAllowed = (userRole?: string) =>
  userRole === role.admin ||
  userRole === role.cordinator ||
  userRole === role.delegate;

export const setShowRevisions = (tfe?: ITFEDDBB) =>
  (tfe?.tfeStatus === statusKey.presented ||
    tfe?.tfeStatus === statusKey.toReview) &&
  tfe.reviewObservations;

export const checkIfSelected = (tfeId: number, selectedTfes?: ITFEDDBB[]) => {
  let found = false;
  selectedTfes?.map(({ id }) => {
    if (id === tfeId) found = true;
  });

  return found;
};

interface IFilterProps {
  userRole?: string;
  userId?: number;
  startDate?: Date;
  endDate?: Date;
}
export const buildFilter = ({
  userRole,
  userId,
  startDate,
  endDate,
}: IFilterProps) => {
  let filter = `?userRole=${userRole}`;
  if (userId) filter = filter + `&userId=${userId}`;
  if (startDate && endDate) {
    let initialDate = datetimeToDDBB(startDate);
    let finalDate = datetimeToDDBB(endDate);
    filter = filter + `&fromDate=${initialDate}&toDate=${finalDate}`;
  }
  return filter;
};

interface IActiveFilterProps {
  active?: string;
  option?: boolean;
}

export const activeFilter = ({ active, option }: IActiveFilterProps) => {
  let filter = `?${active}=${option}`;

  return filter;
};

export const buildCsv = (tfe: ITFEDDBB[]) => {
  let csv: any[] = [];
  tfe.map(
    ({
      id,
      company,
      presentedDate,
      title,
      description,
      objectives,
      center,
      degree,
      companyTutorFullName,
      companyTutorEmail,
      observations,
    }: ITFEDDBB) => {
      csv.push({
        id,
        companyName: company.name,
        cif: company.cif,
        representativeFullName: company?.representativeFullName,
        representativeEmail: company?.representativeEmail,
        representativePhone: company?.representativePhone,
        presentedDate,
        title,
        description,
        objectives,
        center: center?.name,
        degree: degree?.name,
        companyTutorFullName,
        companyTutorEmail,
        observations,
      });
    }
  );

  return csv;
};

export const csvHeaders = [ 
  { label: "Nombre de la empresa/institución", key: "companyName" },
  { label: "CIF", key: "cif" },
  { label: "Persona de contacto", key: "representativeFullName" },
  { label: "Teléfono", key: "representativePhone" },
  { label: "Email", key: "representativeEmail" },
  { label: "Fecha", key: "presentedDate" },
  { label: "Título", key: "title" },
  { label: "Breve explicación de la idea", key: "description" },
  { label: "Objetivos generales del trabajo", key: "objectives" },
  { label: "Centro", key: "center" },
  { label: "Grado al que se dirige la idea", key: "degree" },
  { label: "Tutor designado por la empresa/institución", key: "companyTutorFullName" },
  { label: "Email del tutor designado por la empresa/institución", key: "companyTutorEmail"},
  { label: "Observaciones", key: "observations" },]