import { useState } from "react";

//middleware
import Axios from "axios";
import sha1 from "sha1";
//images
import { create, login } from "../../utils/requests/dynamicReq";
import { verify } from "jsonwebtoken";
import { jwtPassword } from "../../utils/constants";
import { SetCompanyAction, SetUserAction } from "../../redux/actions";
import { role } from "../../utils/roles";
import { paths } from "../../utils/paths";

//redux
import { useDispatch } from "react-redux";
import { validate } from "./validations";
import { errCreateCompany, loginError, unkonwnError } from "../../utils/errors";
import { useHistory } from "react-router";
import { ROUTES } from "../../utils/routes";

//aux

interface IProps {
  setIsLogin(value: boolean): any;
}

export const Register = ({ setIsLogin }: IProps) => {
  const { COMPANY } = paths;

  const source = Axios.CancelToken.source();

  const history = useHistory();

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    //prettier-ignore
    const notValid = validate(name, email, password, repeatPassword, termsAndConditions);
    if (notValid) {
      throwError(notValid);
      return;
    }
    setLoading(true);
    try {
      let valid = await createCompany();
      valid && loginCompany();
    } catch (error) {
      setLoading(false);
      throwError(unkonwnError);
    }
  };

  const createCompany = async () => {
    const encryptedPss = sha1(password);

    const body = { name, email, password: encryptedPss };
    const res: any = await create(COMPANY, body, source);

    if (res?.data?.status === "error") {
      throwError(errCreateCompany);
      return false;
    }
    return true;
  };

  const loginCompany = async () => {
    try {
      const encryptedPss = sha1(password);

      const { token }: any = await login(role.company, email, encryptedPss);

      const { id, name, profileOk }: any = verify(token, jwtPassword);

      sessionStorage.setItem("token", token);
      setLoading(false);
      dispatch(SetCompanyAction({ id, name, email, profileOk }));
      //prettier-ignore
      dispatch(SetUserAction({ token, id, userRole: "company", fullName: name, email, profileOk }));
      // Swal.fire({ icon: "success", text: NewCompanySuccess });
      history.push(`${ROUTES.Company.update}/${id}`);
    } catch (error) {
      throwError(loginError);
    }
  };

  const throwError = (message: string) => {
    setLoading(false);
    setMessage(message);
    setTimeout(() => setMessage(""), 4000);
  };

  const disabled = !email || !password || !repeatPassword;

  return (
    <>
      <div style={{ width: "80%" }}>
        <small>*Nombre de la empresa</small>
        <input
          className="form-control input100 mb-2"
          type="text"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </div>

      <div style={{ width: "80%" }}>
        <small>*Email</small>
        <input
          className="form-control input100 mb-2"
          type="text"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </div>

      <div style={{ width: "80%" }}>
        <small>*Contraseña</small>
        <input
          className="form-control input100 mb-2"
          type="password"
          value={password}
          onChange={({ target: { value } }) => setPassword(value)}
        />
      </div>
      <div style={{ width: "80%" }}>
        <small>*Repetir contraseña</small>
        <input
          className="form-control input100"
          type="password"
          value={repeatPassword}
          onChange={({ target: { value } }) => setRepeatPassword(value)}
        />
        <div>
          <input
            defaultChecked={termsAndConditions}
            onChange={() => setTermsAndConditions(!termsAndConditions)}
            type="checkbox"
            className="mr-2 mt-3"
          />
          <small>Aceptar Términos y Condiciones</small>
        </div>
      </div>
      <button
        style={{ cursor: disabled ? "not-allowed" : "" }}
        className="loginButton2 mt-4"
        disabled={disabled || !!message}
        onClick={handleSubmit}
      >
        {!loading ? (
          "Continuar"
        ) : (
          <span className="spinner-border spinner-border-sm" />
        )}
      </button>
      {message && <span className="text-center">{message}</span>}
      <small className="mt-3 pointer" onClick={() => setIsLogin(true)}>
        Atras
      </small>
    </>
  );
};
