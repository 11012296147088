import { ICompany } from "../../interfaces/ICompany";

interface IProps {
  company?: ICompany;
}

export const Placement = ({ company }: IProps) => {
  return (
    <>
      <div className="row justify-content-center">
        <h5>Lugar de desarrollo del TFE</h5>
      </div>
      <div className="row mt-4">
        <div className="col-sm-2 col-12">
          <h6>Provincia:</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{company?.province}</p>
        </div>
        <div className="col-sm-2 col-12">
          <h6>Localidad:</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{company?.district}</p>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-2 col-12">
          <h6>Dirección:</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{company?.address}</p>
        </div>
        <div className="col-sm-2 col-12">
          <h6>CP:</h6>
        </div>
        <div className="col-sm-4 col-12">
          <p>{company?.postcode}</p>
        </div>
      </div>
    </>
  );
};
