import Swal from "sweetalert2";
import { SwalError } from "../constants";
import { update } from "../requests/dynamicReq";

export const useUpdate = () => {
  interface IUpdate {
    path: string;
    id: number;
    body: any;
    source: any;
    message?: string;
    reload?: boolean;
  }

  const updateIt = ({ path, id, body, source, message, reload }: IUpdate) => {
    message = message || "¿Quieres guardar estos cambios?";
    Swal.fire({
      icon: "question",
      text: message,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        update(path, id, body, source)
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Datos actualizados correctamente",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then(
              ({ isConfirmed }) => isConfirmed && reload && window.location.reload()
            );
          })
          .catch(() => {
            Swal.fire({ icon: "error", text: SwalError });
            return false;
          });
      }
      return "Success";
    });
  };

  return { updateIt };
};
