import { ICompany } from "../interfaces/ICompany";
import { IUser } from "../interfaces/IUser";
import { TAction } from "./types";

export const SetUserAction = (user: IUser): TAction => ({
  type: "SET_USER",
  payload: user,
});

export const UnsetUserAction = (): TAction => ({
  type: "UNSET_USER",
});

export const SetCompanyAction = (company: ICompany): TAction => ({
  type: "SET_COMPANY",
  payload: company,
});

export const UnsetCompanyAction = (): TAction => ({
  type: "UNSET_COMPANY",
});

export const LogoutAction = (): TAction => ({ type: "LOGOUT" });

