/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

//middleware
import Axios from "axios";
import { paths } from "../../../../utils/paths";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";

import { SetTutor } from "../../../../components/TFE/SetTutor";
import { SelectTFE } from "../../../../components/TFE/SelectTFE";
import { SetDate } from "../../../../components/TFE/SetDate";
import { Student } from "../../../../components/TFE/Student";
import { Evaluation } from "../../../../components/TFE/Evaluation";
import { GeneralInfo } from "../../../../components/TFE/GeneralInfo";
import { Placement } from "../../../../components/TFE/Placement";
import { CompanyTutor } from "../../../../components/TFE/CompanyTutor";
import { Logs } from "../../../../components/TFE/Logs";
import { StudentInterest } from "../../../../components/TFE/StudentInterest";
import { role } from "../../../../utils/roles";
import { NotAssigned } from "../../../../components/TFE/NotAssigned";
import { getById } from "../../../../utils/requests/dynamicReq";
import { ITFEDDBB } from "../../../../interfaces/ITFEDDBB";
import { setAccepted, setEditAllowed, setShowRevisions } from "./utils";
import { Revisions } from "../../../../components/TFE/Revisions";
import { Agreements } from "../../../../components/TFE/Agreements";

//utils

export const ReadTFE: FC = () => {
  const { TFE } = paths;
  const { id: userId, userRole } = useSelector(({ user }: IStore) => user as IUser);

  const { id }: any = useParams();
  const history = useHistory();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  const [tfe, setTfe] = useState<ITFEDDBB>();
  const [tfeStatus, setTfeStatus] = useState("");
  const [allowsSelection, setAllowsSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    setIsLoading(true);
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      let response: ITFEDDBB = await getById(TFE, id, source);
      if (isMountedRef.current) {
        console.log(response);

        setIsLoading(false);
        setTfe(response);
        setTfeStatus(response.tfeStatus);
        setAllowsSelection(response.center.allowsSelection);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
      setIsLoading(false);
    }
  };

  const editAllowed = setEditAllowed(userRole);

  const isAccepted = setAccepted(tfeStatus);

  const showRevisions = setShowRevisions(tfe);

  const student = userRole === role.student;

  const assignedToMe = tfe?.student?.id === userId;

  if (isNaN(id) || !id) return <NotAssigned />;

  return (
    <>
      {isLoading && (
        <div className="fullWidth fullHeight center">
          <span className="spinner-grow" style={{ width: "5rem", height: "5rem", backgroundColor: "#cd176f" }} />
        </div>
      )}
      {tfe && (
        <div className="container-fluid pl-4">
          <div className="row mb-2">
            <div className="col-11 d-flex justify-content-between">
              <h5>TFE</h5>
              {student && allowsSelection && <SelectTFE tfe={tfe} />}
              <button className="button-search" onClick={() => history.goBack()}>
                Atrás
              </button>
            </div>
          </div>
          {((editAllowed && isAccepted) || assignedToMe) && (
            <div className="row mt-2">
              <div className="col-sm-5 col-12 whiteDiv mt-2">
                <SetTutor name={tfe.umaTutorFullName} email={tfe.umaTutorEmail} />
              </div>
              <div className="col-1" />
              <div className="col-sm-5 col-12 whiteDiv mt-2">
                <SetDate propsDefended={tfe.defended} propsDefenseDate={tfe.defenseDate} />
              </div>
            </div>
          )}

          <div className="row mt-4">
            <div className="col-sm-11 col-12 whiteDiv">
              <GeneralInfo tfe={tfe} />
            </div>
          </div>

          {showRevisions && (
            <div className="row mt-4">
              <div className="col-sm-11 col-12 whiteDiv" style={{ border: "2px solid red" }}>
                <Revisions tfe={tfe} />
              </div>
            </div>
          )}

          <div className="row mt-4">
            <div className="col-sm-11 col-12 whiteDiv">
              <Placement company={tfe.company} />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-11 col-12 whiteDiv">
              <CompanyTutor tfe={tfe} />
            </div>
          </div>

          {(tfe.studentAgreement || tfe.tutorAgreement) && (
            <div className="row mt-4">
              <div className="col-sm-11 col-12 whiteDiv">
                <Agreements tfe={tfe} />
              </div>
            </div>
          )}

          {editAllowed && (
            <div className="row mt-4">
              <div className="col-sm-11 col-12 whiteDiv">
                <Evaluation tfe={tfe} />
              </div>
            </div>
          )}

          {editAllowed && isAccepted && (
            <>
              <div className="row mt-4">
                <div className="col-sm-11 col-12 whiteDiv">
                  <StudentInterest tfe={tfe} />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-11 col-12 whiteDiv">
                  <Student student={tfe.student} />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-11 col-12 whiteDiv">
                  <Logs />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
