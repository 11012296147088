import { RadialCharts } from "../RadialCharts";
import { buildStatusRadialData } from "../utils";
import { StatusIndex } from "./StatusIndex";

interface IProps {
  data: any[];
  loading: boolean;
}
export const SectionStatus = ({ data, loading }: IProps) => {
  const { tfgArray, tfmArray } = buildStatusRadialData(data);

  return (
    <div className="row">
      <div className="col-sm-6 col-12  mt-4">
        <div className="d-flex whiteDiv responsiveColumn center">
          <div className="col-sm-6 col-12 center">
            {loading ? (
              <span className="spinner-grow bg-pink" />
            ) : (
              !!!tfgArray.length && (
                <p className="text-center">No hay suficientes datos de TFGs</p>
              )
            )}
            {!!tfgArray.length && (
              <RadialCharts
                data={tfgArray}
                innerRadius={80}
                radius={115}
                width={300}
                height={300}
                label="TFG"
              />
            )}
          </div>
          <div className="col" />
          <div className="col-sm-5 col-12 ">
            <StatusIndex />
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-12  mt-4">
        <div className="d-flex whiteDiv responsiveColumn center">
          <div className="col-sm-6 col-12 center">
            {loading ? (
              <span className="spinner-grow bg-pink" />
              ) : (
              !!!tfmArray.length && (
                <p className="text-center">No hay suficientes datos de TFMs</p>
              )
            )}

            {!!tfmArray.length && (
              <RadialCharts
                data={tfmArray}
                innerRadius={80}
                radius={115}
                width={300}
                height={300}
                label="TFM"
              />
            )}
          </div>
          <div className="col" />
          <div className="col-sm-5 col-12 ">
            <StatusIndex />
          </div>
        </div>
      </div>
    </div>
  );
};
