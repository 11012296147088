import { Redirect, Route } from 'react-router-dom';

 export const ProtectedRoute = ({ component: Component, token, ...rest }:any) => {
    return (
      <Route {...rest} render={
        props => {
          if (token) {
            return <Component {...rest} {...props} />
          } else {
            return <Redirect to={ {pathname: '/', state: {from: props.location}}} />
          }
        }
      } />
    )
  }

