/* eslint-disable react-hooks/exhaustive-deps */

//middleware
import { useHistory } from "react-router";
import { ICompany } from "../../interfaces/ICompany";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../utils/routes";
import { Form } from "react-bootstrap";

interface IProps {
  companies: ICompany[];
  setCompanies: any;
  index: number;
  updateCompany: (companiId: number, message: string) => void;
}

export const CompaniesTable = ({ companies, index, setCompanies, updateCompany }: IProps) => {
  const history = useHistory();
 
  const settingActiveToggle = (i:any) => {
    // eslint-disable-next-line eqeqeq
    if (companies[i].active == false) {
      let companyId = companies[i].id;
      let newCompany = [...companies];
      newCompany[i].active = true;
      setCompanies(newCompany);

      let message = "activada";

      setTimeout(() => {
        updateCompany(companyId!, message);
      }, 200);
    // eslint-disable-next-line eqeqeq
    }else if(companies[i].active == true){
      let companyId = companies[i].id;
      let newCompany = [...companies];
      newCompany[i].active = false;
      setCompanies(newCompany);

      let message = "desactivada";

      setTimeout(() => {
        updateCompany(companyId!, message);
      }, 200);
    }
  };


  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">CIF</th>
            <th scope="col">Nombre</th>
            <th scope="col">Ubicación</th>
            <th scope="col">Email</th>
            <th scope="col">Activas</th>
            <th scope="col">TFEs</th>
            <th scope="col">Editar</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {companies?.slice(0, index)?.map(
            //prettier-ignore
            ({id, cif, name, address, province, district, email, tfes, active }: ICompany, i: any) => (
                      <tr key={id}>
                        <td><strong>{cif}</strong></td>
                        <td>{name}</td>
                        <td>{`${address ? address : ""}. ${district ? district: ""}`}</td>
                        <td>{email}</td>
                        <td>
                      <Form>
                        <Form.Check
                          type="switch"
                          id={i+1}
                          checked={active}
                          onChange={() => 
                            settingActiveToggle(i)
                          }
                        />
                      </Form>
                    </td>
                        <td className="pointer c-lightblue" onClick={()=>history.push(`${ROUTES.Admin.Company.read}/${id}`)}>{!!tfes?.length ? "Ver": ""}</td>
                        <td>
                        <FontAwesomeIcon className="mr-2 pointer" color="#0099cc" icon={faEdit} 
                         onClick={()=> history.push(`${ROUTES.Admin.Company.update}/${id}`)}/>
                        </td>
                      </tr>
                    )
          )}
        </tbody>
      </table>
    </>
  );
};
