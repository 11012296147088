import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../utils/routes";

export const NotAssigned = () => {
  const history = useHistory();

  return (
    <div className="container-fluid pl-4">
      <div className="row mb-2">
        <div className="col-11 ">
          <h5>Mi TFE</h5>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-10 col-12 whiteDiv">
          <div className="d-flex flex-column align-items-center justify-content-center mb-3">
            <h5>No tienes ningún TFE asignado</h5>
            <p>En el listado puedes solicitar el que desees</p>

            <div style={{ width: "25%" }}>
              <button
                className="button-save"
                onClick={() => history.push(ROUTES.Student.TFE.all)}
              >
                <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                Ir al listado de TFEs
              </button>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};
