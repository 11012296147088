import React, { FC, useEffect, useState } from "react";

//images
import logo from "../../../assets/images/MARCA_UNIVERSIDAD_BLANCO_TRANS_1.png";

import { useHistory, useLocation } from "react-router";
import { validatePasswords } from "./validations";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { paths } from "../../../utils/paths";
import { create } from "../../../utils/requests/dynamicReq";
import Axios from "axios";
import { SwalError } from "../../../utils/constants";
import sha1 from "sha1";

//aux

export const ChangePassword: FC = () => {
  const history = useHistory();

  const { FORGOTPSS } = paths;
  const source = Axios.CancelToken.source();

  const { pathname }: any = useLocation();
  const id: string = pathname.split("company/")[1].split("/")[0];
  const token: string = pathname.split("/")[4];

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [repeatVisible, setRepeatVisible] = useState(false);

  useEffect(() => {}, []);

  const handleSubmit = async () => {
    const notValid = validatePasswords(newPassword, repeatNewPassword);
    if (notValid) {
      throwError(notValid);
      return;
    }
    Swal.fire({
      icon: "question",
      text: `¿Estás seguro quieres reiniciar la contraseña?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation();
    });
  };

  const handleConfirmation = async () => {
    setLoading(true);

    const pathRestore = FORGOTPSS + "/restore";
    const encryptedPss = sha1(newPassword);

    const body = { id, token, newPassword: encryptedPss };
    create(pathRestore, body, source)
      .then(() => {
        Swal.fire({
          icon: "success",
          text: "Constraseña cambiada correctamente.",
        });
        history.push("/");
      })
      .catch(() => {
        setLoading(true);
        Swal.fire({ icon: "error", text: SwalError });
      });
  };
  const throwError = (message: string) => {
    setLoading(false);
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const disabled = !newPassword || !repeatNewPassword;
  const inputType = visible ? "text" : "password";
  const eyeIcon = visible ? faEye : faEyeSlash;

  const inputType2 = repeatVisible ? "text" : "password";
  const eyeIcon2 = repeatVisible ? faEye : faEyeSlash;

  return (
    // <div >
    <div className="resetPasswordContainer">
      <div className="cardResetPassword">
        <img className="loginLogo mb-2" src={logo} alt="" />

        <h5>Nueva contraseña</h5>
        <p className="textCardPassword">
          Introduce una nueva contraseña para acceder a tu cuenta.
        </p>

        <div>
          <small>Nueva contraseña</small>
          <Form.Group>
            <InputGroup>
              <Form.Control
                type={inputType}
                placeholder="Introducir contraseña"
                className="form-control "
                value={newPassword}
                onChange={({ target: { value } }) => {
                  setMessage("");
                  setNewPassword(value);
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text onClick={() => setVisible(!visible)}>
                  <FontAwesomeIcon color="#242424" icon={eyeIcon} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </div>
        <div>
          <small>Repitir contraseña</small>
          <Form.Group>
            <InputGroup className="inputPassword">
              <Form.Control
                type={inputType2}
                placeholder="Repetir contraseña"
                className="form-control inputPassword"
                value={repeatNewPassword}
                onChange={({ target: { value } }) => {
                  setMessage("");
                  setRepeatNewPassword(value);
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text onClick={() => setRepeatVisible(!repeatVisible)}>
                  <FontAwesomeIcon color="#242424" icon={eyeIcon2} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </div>
        {message && <span className="text-center">{message}</span>}
        <button
          style={{ cursor: disabled ? "not-allowed" : "" }}
          className="loginButton2 mt-4 mb-4"
          disabled={disabled}
          onClick={handleSubmit}
        >
          {/* prettier-ignore */}
          {!loading ? ("Guardar contraseña") : (<span className="spinner-border spinner-border-sm" />)}
        </button>
      </div>
    </div>
  );
};
