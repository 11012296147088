import { Badge } from "./Badge";

interface IProps {
  count: any;
}

export const Header = ({ count }: IProps) => {
  const { centerCount, companyCount, tfgCount, tfmCount } = count;

  return (
    <div className="d-flex center responsiveColumn ">
      <div className="fullWidth mt-2 mr-3">
        <Badge title="Empresas" number={companyCount} />
      </div>
      <div className="fullWidth mt-2 mr-3">
        <Badge title="TFGs" number={tfgCount} />
      </div>
      <div className="fullWidth mt-2 mr-3">
        <Badge title="TFMs" number={tfmCount} />
      </div>
      <div className="fullWidth mt-2 ">
        <Badge title="Centros" number={centerCount} />
      </div>
    </div>
  );
};
