/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { styleIsAllowed } from "../../utils/constants";
import { useParams } from "react-router";
import { paths } from "../../utils/paths";
import axios from "axios";
import { useUpdate } from "../../utils/hooks/useUpdate";

//utils

interface IProps {
  name?: string;
  email?: string;
}

export const SetTutor = ({ name, email }: IProps) => {
  const { updateIt } = useUpdate();
  const { id }: any = useParams();
  const { TFE } = paths;

  const source = axios.CancelToken.source();

  const [umaTutorFullName, setUmaTutorFullName] = useState<string>(name || "");
  const [umaTutorEmail, setUmaTutorEmail] = useState<string>(email || "");

  const disabled = !umaTutorFullName || !umaTutorEmail;

  return (
    <>
      <div className="row justify-content-center">
        <h5>Tutorización Centro</h5>
      </div>
      <div className="row mt-4">
        <div className="col-sm-5 col-12">
          <h6>Nombre Completo:</h6>
        </div>
        <div className="col-sm-7 col-12">
          <input
            type="text"
            className="form-control"
            value={umaTutorFullName}
            onChange={({ target: { value } }) => setUmaTutorFullName(value)}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-5 col-12">
          <h6>Correo electrónico:</h6>
        </div>
        <div className="col-sm-7 col-12">
          <input
            type="text"
            className="form-control"
            value={umaTutorEmail}
            onChange={({ target: { value } }) => setUmaTutorEmail(value)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 d-flex justify-content-end ">
          <button
            className="button-save"
            style={styleIsAllowed(disabled)}
            disabled={disabled}
            onClick={() =>
              updateIt({ path: TFE, id, body: { umaTutorFullName, umaTutorEmail }, source })
            }
          >
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </button>
        </div>
      </div>
    </>
  );
};
