import { statusColorIndex } from "../../../utils/constants";
import { BulletPoints } from "../BulletPoints";

export const StatusIndex = () => {
  return (
    <>
      <h6 className="mb-3">Estado</h6>
      {statusColorIndex.map(({ label, color }, i) => (
        <BulletPoints key={i} label={label} color={color} />
      ))}
    </>
  );
};
