/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { useSelector } from "react-redux";

//components
import { More } from "../../../components/More/More";
import { StudentTFE } from "../../../components/TFE/StudentTFE";

//interfaces
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { ITFEDDBB } from "../../../interfaces/ITFEDDBB";
import { IStudent } from "../../../interfaces/IStudent";

//utils
import { buildFilter } from "../Common/ManageTFE/utils";
import { getAll, getById } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";

export const AllStudentTfes: FC = () => {
  const { TFE, STUDENTS } = paths;

  //prettier-ignore
  const { userRole, id: userId, studentId } = useSelector(({ user }: IStore) => user as IUser);

  const [tfes, setTfes] = useState<ITFEDDBB[]>([]);
  const [displayTFEs, setDisplayTFEs] = useState<ITFEDDBB[]>([]);
  const [selectedTfes, setSelectedTfes] = useState<ITFEDDBB[] | undefined>();
  const [allowsSelection, setAllowsSelection] = useState(false);

  const [index, setIndex] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const filter: string = buildFilter({ userRole, userId });
      const responseTFEs = await getAll(TFE, source, filter);

      //prettier-ignore
      const { selectedTfes, degree: {center} }: IStudent = await getById(STUDENTS, studentId!, source);

      if (isMountedRef.current) {
        setIsLoading(false);
        setTfes(responseTFEs);
        setDisplayTFEs(responseTFEs?.slice(0, index));
        setSelectedTfes(selectedTfes);
        setAllowsSelection(!!center?.allowsSelection);
      }
    } catch (error) {
      isMountedRef.current && setIsLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  const refreshIndex = (number: number) => {
    setIndex(number);
    setDisplayTFEs(tfes?.slice(0, number));
  };

  const subtitle = allowsSelection
    ? `*Para mostrar tu interés en un TFE, pulsa el botón 'Más info.' y
    luego "Mostrar interés en TFE".`
    : `*Por favor contacta con tu centro para mostrar tu interés en un TFE.`;

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-sm-11 col-12">
            <h5>Listado TFEs</h5>
            <small>{subtitle}</small>
          </div>
        </div>
        {displayTFEs.map((tfe) => (
          <div key={tfe.id} className="row mb-4">
            <div className="col-sm-11 col-12 whiteDiv">
              <StudentTFE
                tfe={tfe}
                selectedTfes={selectedTfes}
                allowsSelection={allowsSelection}
              />
            </div>
          </div>
        ))}
        <More
          displayData={tfes}
          isLoading={isLoading}
          refreshIndex={refreshIndex}
          index={index}
          amount={5}
        />
      </div>
    </>
  );
};
