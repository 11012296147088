import { statusKey } from "../../utils/constants";

export const buildBadge = (status: string) => {
  switch (status) {
    case statusKey.drafted:
      return { draftColor: "orange", draftLabel: "Borrador" };
    case statusKey.presented:
      return { draftColor: "#189BCC", draftLabel: "Presentado" };
    case statusKey.defended:
      return { draftColor: "black", draftLabel: "Defendido" };
    case statusKey.rejected:
      return { draftColor: "red", draftLabel: "Rechazado" };
    case statusKey.toReview:
      return { draftColor: "#CD176F", draftLabel: "Propuesta de Cambios" };
    case statusKey.accepted:
      return { draftColor: "#219653", draftLabel: "Aceptado" };
    case statusKey.signedAgreement:
      return { draftColor: "#5D6163", draftLabel: "Convenio firmado" };
    case statusKey.assigned:
      return { draftColor: "#12385E", draftLabel: "Asignado" };
    default:
      return { draftColor: "", draftLabel: "" };
  }
};
