import { ITFE } from "../../../../interfaces/ITFE";
import { ITFEUpdate } from "../../../../interfaces/ITFEUpdate";
import { statusKey } from "../../../../utils/constants";
import { validateEmail } from "../../../../utils/validations";

//prettier-ignore
export const validateTFE = (tfe: ITFE) => {
  if (!tfe.tfeType) return "Por favor seleccione TFG o TFM";
  if (!tfe.centerId) return "Por favor elija un centro";
  if (!tfe.title) return "Por favor escriba un título";
  if (!tfe.description) return "Por favor escriba una descripción";
  if (!tfe.companyTutorFullName) return "Por favor escriba nombre de tutor de la empresa";
  if (!tfe.companyTutorEmail || !validateEmail(tfe.companyTutorEmail)) return "Por favor escriba el email de tutor de la empresa";
  if (tfe.studentAgreement) {
    if(!tfe.agreementStudentFullName) return "Por favor escriba el nombre del estudiante del acuerdo";
    if(!tfe.agreementStudentEmail) return "Por favor escriba el email del estudiante del acuerdo";
  }
  if (tfe.tutorAgreement) {
    if(!tfe.umaTutorFullName) return "Por favor escriba el nombre del tutor del acuerdo";
    if(!tfe.umaTutorEmail) return "Por favor escriba el email del tutor del acuerdo";
  }
  return ""
};

export const validateUpdate = (update: ITFEUpdate) => {
  if (!update.delegateId) {
    if (
      update.tfeStatus === statusKey.drafted ||
      update.tfeStatus === statusKey.presented
    )
      return "No se detectan cambios";
  }
  if (update.tfeStatus === statusKey.toReview && !update.reviewObservations)
    return "Por favor escriba propuesta de cambios";
  if (update.tfeStatus === statusKey.accepted && !update.degreeId)
    return "Por favor asocie un estudio";
};
