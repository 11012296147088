import { useState } from "react";
import { RadialChart } from "react-vis";
import { LabelSeries } from "react-vis";

export const RadialCharts = ({
  data,
  innerRadius,
  radius,
  width,
  height,
  label,
}) => {
  const [dinamicLabel, setDinamiclLabel] = useState("");

  return (
    <RadialChart
      colorType="literal"
      innerRadius={innerRadius}
      radius={radius}
      data={data}
      width={width}
      height={height}
      animated
      onValueMouseOver={(d) => setDinamiclLabel(`${d.label} (${d.quantity})`)}
      onValueMouseOut={() => setDinamiclLabel("")}
    >
      <LabelSeries
        data={[
          {
            x: 0,
            y: 0,
            label: dinamicLabel || label,
            style: { textAnchor: "middle" },
          },
        ]}
      />
    </RadialChart>
  );
};
