import { FC } from "react";
//css
import "../App.css";

//middleware
import { useSelector } from "react-redux";

//components
import { Sidebar } from "../components/Sidebar/Sidebar";

//utils
import { role } from "../utils/roles";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//routes
import { Admin } from "./admin";
import { Coordinator } from "./coordinator";
import { Company } from "./company";
import { Common } from "./common";
import { Student } from "./student";
import { ChooseDegree } from "../components/Modals/ChooseDegree";

const LoggedRoutes: FC = () => {
  let { userRole, degreeId } = useSelector(({ user }: IStore) => user as IUser);

  const admin = userRole === role.admin;
  const company = userRole === role.company;
  const coordinator = userRole === role.cordinator;
  const student = userRole === role.student;

  if (student && !degreeId) return <ChooseDegree />;

  return (
    <>
      <div className="container-fluid">
        <div className="row wrapper min-vh-100 flex-column flex-md-row ">
          <Sidebar />
          <main className="col bg-faded py-3 mainMargin">
            {student && <Student />}
            {admin && <Admin />}
            {(admin || coordinator) && <Coordinator />}
            {company && <Company />}
            {!company && <Common />}
          </main>
        </div>
      </div>
    </>
  );
};

export default LoggedRoutes;
