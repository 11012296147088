import { IRadialChart } from "../../interfaces/IRadialChart";
import { centerColorIndex, statusColorIndex, tfeValue } from "../../utils/constants";

export const buildStatusRadialData = (data: any[]) => {
  let tfgArray: IRadialChart[] = [];
  let tfmArray: IRadialChart[] = [];
  data.forEach((d) => {
    if (d.tfeType === tfeValue.tfg)
      tfgArray.push({
        angle: d.quantity,
        quantity: d.quantity,
        label: statusColorIndex.find(({ name }) => name === d.tfeStatus)!.label,
        color: statusColorIndex.find(({ name }) => name === d.tfeStatus)!.color,
      });
    if (d.tfeType === tfeValue.tfm)
      tfmArray.push({
        angle: d.quantity,
        quantity: d.quantity,
        label: statusColorIndex.find(({ name }) => name === d.tfeStatus)!.label,
        color: statusColorIndex.find(({ name }) => name === d.tfeStatus)!.color,
      });
  });
  return { tfgArray, tfmArray };
};

export const buildCenterRadialData = (data: any[]) => {
  let tfgArray: IRadialChart[] = [];
  let tfmArray: IRadialChart[] = [];
  data.forEach((d) => {
    if (d.tfeType === tfeValue.tfg)
      tfgArray.push({
        angle: d.quantity,
        quantity: d.quantity,
        label: d.name,
        color: centerColorIndex.find(({ name }) => name === d.name)!.color,
      });
    if (d.tfeType === tfeValue.tfm)
      tfmArray.push({
        angle: d.quantity,
        quantity: d.quantity,
        label: d.name,
        color: centerColorIndex.find(({ name }) => name === d.name)!.color,
      });
  });
  return { tfgArray, tfmArray };
};
