/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

//middleware
import Axios from "axios";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ICenter } from "../../interfaces/ICenter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { CoordinatorModal } from "../Modals/CoordinatorModal";
import { getById, update } from "../../utils/requests/dynamicReq";
import { paths } from "../../utils/paths";
import Swal from "sweetalert2";
import { SwalError } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { activeFilter } from "../../views/Logged/Common/ManageTFE/utils";

interface IProps {
  centers: any[];
  setCenters: any;
  updateCenter: (centerId: number) => void;
}

export const CenterList = ({ centers, setCenters, updateCenter }: IProps) => {
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const history = useHistory();

  const { CENTERS, DELEGATESBYCENTER, USERS } = paths;

  const [coordinator, setCoordinator] = useState("");
  const [coordinatorId, setCoordinatorId] = useState<number>();
  const [newCoordinatorId, setNewCoordinatorId] = useState<number>();
  const [center, setCenter] = useState("");
  const [delegates, setDelegates] = useState<any[]>([]);
  const [pickedId, setPickedId] = useState<number>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const settingAgreementToggle = (i: number) => {
    // eslint-disable-next-line eqeqeq
    if (centers[i].allowsAgreements == false) {
      let centerId = centers[i].id;
      let newCenter = [...centers];
      newCenter[i].allowsAgreements = true;
      setCenters(newCenter);
      setTimeout(() => {
        updateCenter(centerId!);
      }, 200);
      // eslint-disable-next-line eqeqeq
    } else if (centers[i].allowsAgreements == true) {
      let centerId = centers[i].id;

      let newCenter = [...centers];
      newCenter[i].allowsAgreements = false;
      setCenters(newCenter);
      setTimeout(() => {
        updateCenter(centerId!);
      }, 200);
    }
  };

  const settingSelectionToggle = (i: number) => {
    // eslint-disable-next-line eqeqeq
    if (centers[i].allowsSelection == false) {
      let centerId = centers[i].id;
      let newCenter = [...centers];
      newCenter[i].allowsSelection = true;
      setCenters(newCenter);
      setTimeout(() => {
        updateCenter(centerId!);
      }, 200);
      // eslint-disable-next-line eqeqeq
    } else if (centers[i].allowsSelection == true) {
      let centerId = centers[i].id;

      let newCenter = [...centers];
      newCenter[i].allowsSelection = false;
      setCenters(newCenter);
      setTimeout(() => {
        updateCenter(centerId!);
      }, 200);
    }
  };

  //-----------------------------------------

  const dismissNewCoordinator = () => {
    setNewCoordinatorId(0);
    setShowModal(false);
  };

  const openChangeCoordinator = async (
    id: number,
    name: string,
    coordinatorId: number,
    center: string
  ) => {
    setPickedId(id);
    setCoordinator(name);
    setCoordinatorId(coordinatorId);
    setCenter(center);
    try {
      const filter: string = activeFilter({ active: "active", option: true });
      const delegates = await getById(DELEGATESBYCENTER, id, source, filter);
      if (isMountedRef.current) {
        setDelegates(delegates);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
    setShowModal(true);
  };

  const handleNewCoordinator = (newCoordinatorId: number) => {
    setNewCoordinatorId(newCoordinatorId);
  };

  const handleSubmit = () => {
    let newCoordinatorCenter = buildNewCenter();

    Swal.fire({
      icon: "question",
      text: `¿Quieres cambiar el coordinador/a de este centro?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(newCoordinatorCenter);
    });
  };

  const handleConfirmation = (newCoordinatorCenter: any) => {
    update(CENTERS, pickedId!, newCoordinatorCenter, source)
      .then(async () => {
        let newDelegate = { userRole: "delegate" };
        await update(USERS, coordinatorId!, newDelegate, source);
        let newCoordinator = { userRole: "coordinator" };
        await update(USERS, newCoordinatorId!, newCoordinator, source);
        Swal.fire({
          icon: "success",
          text: `Coordinador/a cambiado correctamente.`,
        });
        setShowModal(false);
        let currentPath = window.location.pathname;
        history.replace(`${currentPath}/replace`);
        setTimeout(() => history.replace(currentPath), 250);
      })
      .catch(() => Swal.fire({ icon: "error", text: SwalError }));
  };

  const buildNewCenter = () => ({
    coordinatorId: newCoordinatorId,
  });

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      "El centro permite que el alumno muestre interés por los TFE"
    </Tooltip>
  );

  const renderAgreementTooltip = (props: any) => (
    <Tooltip id="button-tooltip-agreement" {...props}>
      "El centro permite que el alumno tenga un preacuerdo con la empresa"
    </Tooltip>
  );

  return (
    <>
      <table className="table table-hover text-center">
        <thead className="bg-black text-light">
          <tr>
            <th scope="col">Centro</th>
            <th scope="col">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderAgreementTooltip}
              >
                <div className="text-light pt-2 pointer">Preacuerdo</div>
              </OverlayTrigger>
            </th>

            <th scope="col">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <div className="text-light pt-2 pointer">Selección TFE</div>
              </OverlayTrigger>
            </th>
            <th scope="col">Coordinador</th>
            <th scope="col">Editar</th>
          </tr>
        </thead>
        <tbody className="bg-fffff">
          {centers
            ?.map(
              (
                {
                  id,
                  name,
                  allowsAgreements,
                  allowsSelection,
                  coordinator,
                }: ICenter,
                i: any
              ) => (
                <tr key={i}>
                  <td>{name}</td>
                  <td>
                    <Form>
                      <Form.Check
                        type="switch"
                        id={i + 1}
                        checked={allowsAgreements}
                        onChange={() => settingAgreementToggle(i)}
                      />
                    </Form>
                  </td>
                  <td>
                    <Form>
                      <Form.Check
                        type="switch"
                        id={new Date().getTime()! + i}
                        checked={allowsSelection}
                        onChange={() => settingSelectionToggle(i)}
                      />
                    </Form>
                  </td>
                  <td>{coordinator?.fullName}</td>
                  <td>
                    <FontAwesomeIcon
                      className="mr-2 pointer"
                      icon={faEdit}
                      color="#0099cc"
                      onClick={() =>
                        openChangeCoordinator(
                          id,
                          coordinator?.fullName!,
                          coordinator?.id!,
                          name
                        )
                      }
                    />
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
      <CoordinatorModal
        coordinator={coordinator}
        delegates={delegates}
        newCoordinatorId={newCoordinatorId!}
        handleNewCoordinator={handleNewCoordinator}
        handleSubmit={handleSubmit}
        center={center}
        show={showModal}
        onHide={dismissNewCoordinator}
      />
    </>
  );
};
