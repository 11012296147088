/* eslint-disable react-hooks/exhaustive-deps */
// Aqui se podrán ver, damos de alta y asignamos un rol (coordinador o delegado)
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

//middleware
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { duplicateEmail, SwalError } from "../../../../utils/constants";

import { role } from "../../../../utils/roles";

import {
  create,
  getAll,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { paths } from "../../../../utils/paths";
import { ICoordinator } from "../../../../interfaces/ICoordinator";
import { ICenter } from "../../../../interfaces/ICenter";
import { validateDelegate } from "./validations";
import { ROUTES } from "../../../../utils/routes";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/IStore";
import { IUser } from "../../../../interfaces/IUser";

export const ManageCoordinators: FC = () => {
  const { id: userId, userRole: reduxUserRole } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const { id }: any = useParams();

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const history = useHistory();

  const { USERS, CENTERS } = paths;

  const [fullName, setFullName] = useState("");
  const [userRole, setUserRole] = useState("delegate");
  const [email, setEmail] = useState("");
  const [pickedCenter, setPickedCenter] = useState<number>();
  const [centers, setCenters] = useState<ICenter[]>([]);

  const [message, setMessage] = useState("");
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadEditData();
    reduxUserRole === role.cordinator && loadDefaultCoordinatorData();
    loadDefaultData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [isCreate]);

  const loadDefaultData = async () => {
    try {
      const responseCenters = await getAll(CENTERS, source);
      if (isMountedRef.current) {
        setCenters(responseCenters);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const loadEditData = async () => {
    try {
      const responseDelegate = await getById(USERS, id, source);

      isMountedRef.current && build(responseDelegate);
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const build = (c: any) => {
    setFullName(c.fullName!);
    setEmail(c.email!);
    setUserRole(c.userRole!);
    setPickedCenter(c?.centers[0]?.id);
  };

  const loadDefaultCoordinatorData = async () => {
    try {
      const userResponse = await getById(USERS, userId, source);
      if (isMountedRef.current) {
        setPickedCenter(userResponse?.centers[0]?.id);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  //--------------------------------

  //--------------------------------

  const handleSubmit = () => {
    const delegate: ICoordinator = buildToSend();
    const notValid = validateDelegate(delegate, isCreate, pickedCenter);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear un" : "editar este";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} delegado?`,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(delegate);
    });
  };

  const handleConfirmation = (delegate: ICoordinator) => {
    let createOrUpdate = isCreate
      ? create(USERS, delegate, source)
      : update(USERS, id, delegate, source);
    createOrUpdate
      .then(async () => {
        isMountedRef.current && setIsCreate(false);
        let createOrUpdateText = isCreate ? "creado" : "editado";
        Swal.fire({
          icon: "success",
          text: `Coordinador ${createOrUpdateText} correctamente.`,
        });

        history.push(ROUTES.Common.Coordinators.all);
      })
      .catch((error) => {
        let errorMessage = SwalError;
        if(error.response.data.status === "duplicate")
          errorMessage = duplicateEmail;        
        Swal.fire({ icon: "error", text: errorMessage });
      });
  };

  const buildToSend = () =>{

   const trimmedEmail = email.trim() 
   
   const delegateToSend =  !isCreate ? {fullName, email: trimmedEmail}: {fullName, email: trimmedEmail, userRole, centerIds: [+pickedCenter!]};
      
   return delegateToSend
  
  }

  const header = isCreate ? "Nuevo Delegado/a" : "Editar Delegado/a";
  const coordinator = reduxUserRole === role.cordinator;

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h2>{header}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-12 whiteDiv p-4">
            <div className="form-row">
              <div className="col-sm-6 col-12">
                <h6>*Nombre</h6>
                <input
                  type="text"
                  className="form-control"
                  value={fullName}
                  onChange={({ target: { value } }) => setFullName(value)}
                />
              </div>

              <div className="col-sm-6 col-12">
                <h6>*Correo electrónico</h6>
                <input
                  className="form-control"
                  value={email}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </div>
            </div>

            {(isCreate && !coordinator) && (
              <div className="form-row mt-2">
                <div className="col-sm-6 col-12">
                  <h6>*Centro</h6>
                  <select
                    className="form-control input"
                    value={pickedCenter}
                    disabled={!isCreate || coordinator}
                    onChange={({ target: { value } }) => setPickedCenter(+value)}
                  >
                    <option value={""}>Elige Centro</option>
                    {centers.map(({ name, id }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-6 col-12">
                  <h6>Cargo</h6>
                  <input
                    className="form-control input"
                    value={userRole === "delegate" ? "Delegado" : "Coordinador"}
                    onChange={({ target: { value } }) => setUserRole(value)}
                    readOnly
                  />
                </div>
              </div>
            )}

            <div className="form-row mt-4">
              <div className="col-12 d-flex justify-content-center">
                <div>
                  <button className="button-search" onClick={handleSubmit}>
                    <FontAwesomeIcon className="mr-2" icon={faSave} />
                    Guardar
                  </button>
                </div>
              </div>
            </div>
            <div className="form-row mt-2 text-danger">
              <div className="col-12 d-flex justify-content-center">
                <p>{message} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
