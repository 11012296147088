import { useEffect, useState } from "react";

//middleware
import { useDispatch } from "react-redux";
import sha1 from "sha1";

//images
import { login } from "../../utils/requests/dynamicReq";
import { verify } from "jsonwebtoken";
import { jwtPassword } from "../../utils/constants";
import { SetCompanyAction, SetUserAction } from "../../redux/actions";
import { role } from "../../utils/roles";
import { validate } from "./validations";
import { loginError } from "../../utils/errors";
import { useHistory } from "react-router";
import { ROUTES } from "../../utils/routes";
import { Link } from "react-router-dom";

//aux

interface IProps {
  setIsLogin(value: boolean): any;
}

export const Login = ({ setIsLogin }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {}, []);

  const loginUser = () => {
    setLoadingUser(true);
    window.location.replace("https://back.impulsotfe.uma.es/login-idp");
  };

  const handleSubmit = async () => {
    const notValid = validate(email, password);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    setLoadingCompany(true);
    loginCompany();
  };

  const loginCompany = async () => {
    try {
      const encryptedPss = sha1(password);

      const { token }: any = await login(role.company, email, encryptedPss);

      //prettier-ignore
      const { id, name, profileOk, role: userRole, active }: any = verify( token, jwtPassword);

      sessionStorage.setItem("token", token);

      setLoadingCompany(false);

      //prettier-ignore
      dispatch(SetUserAction({ token, id, userRole, isAdmin: userRole === role.admin, profileOk, active, fullName: name, email }));
      dispatch(SetCompanyAction({ id, name, email, profileOk, active }));

      history.push(`${ROUTES.Company.update}/${id}`);
    } catch (error) {
      throwError(loginError);
    }
  };

  const throwError = (message: string) => {
    setLoadingCompany(false);
    setMessage(message);
    setTimeout(() => {
      setMessage("");
      setPassword("");
    }, 4000);
  };

  const disabled = !email || !password;

  return (
    <>
      <h5>Acceso UMA</h5>
      {/* prettier-ignore */}
      <button className="loginButton mt-2 mb-4" onClick={loginUser}>
        {!loadingUser ? ("Entrar con identificación iDUMA") : ( <span className="spinner-border spinner-border-sm" />)}
      </button>
      <h5 className="mt-5">Acceso Empresas / Entidades</h5>
      <input
        className="form-control input80 mt-3"
        type="text"
        placeholder="Introducir email"
        value={email}
        onChange={({ target: { value } }) => {
          setMessage("");
          setEmail(value);
        }}
      />

      <input
        className="form-control input80 mt-3"
        type="password"
        placeholder="Escribir contraseña"
        value={password}
        onChange={({ target: { value } }) => {
          setMessage("");
          setPassword(value);
        }}
      />
      {message && <span className="text-center">{message}</span>}
      <button
        style={{ cursor: disabled ? "not-allowed" : "" }}
        className="loginButton2 mt-4"
        disabled={disabled || !!message}
        onClick={handleSubmit}
      >
        {!loadingCompany ? "Entrar" : <span className="spinner-border spinner-border-sm" />}
      </button>
      <Link className="c-white mt-2" to={ROUTES.Reset_Password}>
        ¿Has olvidado la contraseña?
      </Link>
      <button className="loginButton mt-4" onClick={() => setIsLogin(false)}>
        Crear cuenta empresa
      </button>

    </>
  );
};

