import axios from "axios";
import { useParams } from "react-router-dom";
import { IStudent } from "../../interfaces/IStudent";
import { useUpdate } from "../../utils/hooks/useUpdate";
import { paths } from "../../utils/paths";

interface IProps {
  student?: IStudent;
}

export const Student = ({ student }: IProps) => {

  const source = axios.CancelToken.source();

  const { TFE } = paths;

  const { id }: any = useParams();

  const { updateIt } = useUpdate();

  const handleSubmit = ( ) => {
    const body = { studentId: null, assigned: false };
    const message = `¿Quieres desasignar este TFE?`;
    updateIt({ path: TFE, id, body, source, message, reload: true });
  };

  return (
    <>
      <div className="row justify-content-center">
        <h5>Estudiante Asignado</h5>
      </div>
      {!!!student?.id ? (
        <div className="row justify-content-center mt-4">
          <p>Sin Asignación</p>
        </div>
      ) : (
        <div className="row mt-4">
            <div className="col-sm-4 col-12 d-flex">
              <h6 className="mt-1 mr-2">Nombre:</h6>
              <p>{student.user?.fullName}</p>
            </div>
            <div className="col-sm-5 col-12 d-flex">
              <h6 className="mt-1 mr-2">Email:</h6>
              <p>{student.user?.email}</p>
            </div>
            <div className="col-sm-2 col-12">
              <span
                className="pointer c-lightblue"
                onClick={() => handleSubmit()}
              >
                Desasignar
              </span>
            </div>
        </div>
      )}
    </>
  );
};
