/* eslint-disable react-hooks/exhaustive-deps */
//middleware
import Axios from "axios";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { IStore } from "../../interfaces/IStore";
import { IUser } from "../../interfaces/IUser";

import { ROUTES } from "../../utils/routes";
import { statusKey } from "../../utils/constants";
import { role } from "../../utils/roles";
import { update } from "../../utils/requests/dynamicReq";
import { paths } from "../../utils/paths";

interface IProps {
  id: number;
  tfeStatus: string;
  delegate?: IUser;
}

export const ReadOrEdit = ({ tfeStatus, id, delegate }: IProps) => {

  let { userRole } = useSelector(({ user }: IStore) => user as IUser);

  const { TFE } = paths;

  const source = Axios.CancelToken.source();
  const history = useHistory();

  const isCompany = userRole === role.company;
  const isAdmin = userRole === role.admin;
  const isCordinator = userRole === role.cordinator;
  const isDelegate = userRole === role.delegate;

  const draftedOrReview =
    tfeStatus === statusKey.drafted || tfeStatus === statusKey.toReview;

  const handleClick = () => {
    if (isDelegate) {
      cleanNotification();
      readRoute();
      return;
    }

    if (isCordinator) {
      !delegate && cleanNotification();
      readRoute();
      return;
    }

    if (isAdmin) readRoute();

    if (isCompany) draftedOrReview ? updateRouteCompany() : readRouteCompany();
  };

  const readRoute = () => history.push(`${ROUTES.Common.TFE.read}/${id}`);
  const cleanNotification = () => update(TFE, id, { notification: false }, source);

  const updateRouteCompany = () => history.push(`${ROUTES.Company.TFE.update}/${id}`);
  const readRouteCompany = () => history.push(`${ROUTES.Company.TFE.read}/${id}`);

  return (
    <span className="pointer c-lightblue" onClick={() => handleClick()}>
      {draftedOrReview ? "Editar" : "Ver"}
    </span>
  );
};
