/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
//css
import "./../Sidebar.css";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  faSignOutAlt,
  faCalendar,
  faBell,
  faDoorClosed,
  faVial,
  faIndustry,
  faUniversity,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../../utils/routes";

interface IProps {
  logout: () => void;
}

export const AdminSidebar = (props: IProps) => {
  //prettier-ignore
  const { logout } = props;

  return (
    <Nav className="navbar navbar-expand-md navbar-dark  align-items-start flex-md-column flex-row bg-blue ">
      <a
        href="void(0);"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#sidebarID"
      >
        <span className="navbar-toggler-icon"></span>
      </a>
      <div className="collapse navbar-collapse sidebar" id="sidebarID">
        <ul className="flex-column navbar-nav w-100 justify-content-between">
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Common.Inbox.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faBell} />
                <span>Buzón TFE</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Common.TFE.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faCalendar} />
                <span>Listado TFE</span>
              </NavLink>
            </Nav.Item>
          </li>
          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Company.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faIndustry} />
                <span>Empresas</span>
              </NavLink>
            </Nav.Item>
          </li>

          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Users}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faUser} />
                <span>Usuarios</span>
              </NavLink>
            </Nav.Item>
          </li>

          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Common.Coordinators.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faVial} />
                <span>Coordinación</span>
              </NavLink>
            </Nav.Item>
          </li>

          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Schools.all}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faUniversity} />
                <span>Centros</span>
              </NavLink>
            </Nav.Item>
          </li>

          <li>
            <Nav.Item>
              <NavLink className="nav-link pl-0" to={ROUTES.Admin.Statistics}>
                <FontAwesomeIcon className="iconClass mr-3" icon={faDoorClosed} />
                <span>Estadísticas</span>
              </NavLink>
            </Nav.Item>
          </li>
         
          <li className="nav-item responsiveLogout" onClick={logout}>
            <a className="nav-link pl-0" href="#">
              <FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
              <span>Cerrar sesión</span>
            </a>
          </li>
        </ul>
      </div>
    </Nav>
  );
};
