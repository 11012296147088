import { FC } from "react";

//middleware
import { useSelector } from "react-redux";

//utils
import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { ROUTES } from "../utils/routes";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//components
import { ManageTFE } from "../views/Logged/Common/ManageTFE/ManageTFE";
import { ManageCompany } from "../views/Logged/Common/Company/ManageCompany";
import { ReadTFE } from "../views/Logged/Common/ManageTFE/ReadTFE";
import { TFEListComponent } from "../views/Logged/Common/ManageTFE/ListTFES/TFEListComponent";

export const Company: FC = () => {
  let { token } = useSelector(({ user }: IStore) => user as IUser);
  //prettier-ignore
  return (
    <>
      <ProtectedRoute exact path={[ROUTES.Company.create, `${ROUTES.Company.update}/:id`]} component={ManageCompany} token={token}/>
      <ProtectedRoute exact path={[ROUTES.Company.TFE.create, `${ROUTES.Company.TFE.update}/:id`]} component={ManageTFE} token={token}/>

      <ProtectedRoute exact path={ROUTES.Company.TFE.all} component={TFEListComponent} token={token}/>
      <ProtectedRoute exact path={`${ROUTES.Company.TFE.read}/:id`} component={ReadTFE} token={token}/>
    </>
  );
};
