import { ITFEDDBB } from "../../interfaces/ITFEDDBB";

interface IProps {
  tfe?: ITFEDDBB;
}

export const Agreements = ({ tfe }: IProps) => {
  return (
    <>
      {tfe?.studentAgreement && (
        <>
          <div className="row justify-content-center">
            <h5>Acuerdo con estudiante y/o tutor</h5>
          </div>
          <div className="row mt-4">
            <div className="col-sm-2 col-12">
              <h6>Estudiante:</h6>
            </div>
            <div className="col-sm-4 col-12">
              <p>{tfe?.agreementStudentFullName}</p>
            </div>
            <div className="col-sm-2 col-12">
              <h6>Email:</h6>
            </div>
            <div className="col-sm-4 col-12">
              <p>{tfe?.agreementStudentEmail}</p>
            </div>
          </div>
        </>
      )}
      {tfe?.tutorAgreement && (
        <>
          <div className="row mt-4">
            <div className="col-sm-2 col-12">
              <h6>Tutor:</h6>
            </div>
            <div className="col-sm-4 col-12">
              <p>{tfe?.umaTutorFullName}</p>
            </div>
            <div className="col-sm-2 col-12">
              <h6>Email:</h6>
            </div>
            <div className="col-sm-4 col-12">
              <p>{tfe?.umaTutorEmail}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
