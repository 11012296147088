/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

//middleware
import { getById } from "../../utils/requests/dynamicReq";
import axios from "axios";
import { paths } from "../../utils/paths";

import { useParams } from "react-router";
import { ILog } from "../../interfaces/ILog";
import { dateForTFE } from "../../utils/auxiliar/dates";

export const Logs = () => {
  const { id }: any = useParams();

  const isMountedRef = useRef(false);
  const source = axios.CancelToken.source();
  const { LOGS } = paths;

  const [logs, setLogs] = useState<ILog[]>([]);

  useEffect(() => {
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const responseLogs = await getById(LOGS, id, source);
      isMountedRef.current && setLogs(responseLogs);
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  return (
    <>
      <div className="row justify-content-center mb-3">
        <h5>Historial TFE</h5>
      </div>

      {logs.map(({ id, createdAt, description }) => (
        <div className="row mt-1" key={id}>
          <div className="col-sm-2 col-12">
            <h6>{dateForTFE(new Date(createdAt))}</h6>
          </div>
          <div className="col-sm-10 col-12">
            <p>{description}</p>
          </div>
        </div>
      ))}
    </>
  );
};
