/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";

//middleware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

//hooks
import { useUpdate } from "../../utils/hooks/useUpdate";
import { useParams } from "react-router";

//utils
import { datetimeToDDBB, utcToLocalTime } from "../../utils/auxiliar/dates";
import { paths } from "../../utils/paths";
import { styleIsAllowed } from "../../utils/constants";

interface IProps {
  propsDefended: boolean;
  propsDefenseDate?: any;
}
export const SetDate = ({ propsDefended, propsDefenseDate }: IProps) => {
  let date = propsDefenseDate && new Date(utcToLocalTime(propsDefenseDate));

  const { updateIt } = useUpdate();

  const { id }: any = useParams();
  const { TFE } = paths;

  const source = axios.CancelToken.source();

  const [defenseDate, setDefenseDate] = useState<Date | undefined>(date);
  const [defended, setDefended] = useState<boolean>(propsDefended);

  const handleUpdate = () => {
    const body = { defenseDate: datetimeToDDBB(defenseDate!), defended };
    updateIt({ path: TFE, id, body, source });
  };

  const disabled = !defenseDate;

  return (
    <>
      <div className="row justify-content-center">
        <h5>Datos Defensa</h5>
      </div>
      <div className="row mt-4">
        <div className="col-sm-2 col-12">
          <h6 className="mt-2">Fecha:</h6>
        </div>
        <div className="col-sm-5 col-12">
          <DatePicker
            className="form-control bg-fafafa"
            placeholderText="Día"
            selected={defenseDate}
            onChange={(date: Date) => setDefenseDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            dateFormat=" dd/MM/yyyy"
          />
        </div>
        <div className="col-sm-5 col-12">
          <DatePicker
            className="form-control bg-fafafa"
            selected={defenseDate}
            onChange={(date: Date) => setDefenseDate(date)}
            onChangeRaw={(e: any) => e.preventDefault()}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            placeholderText="Hora"
            timeCaption="Hora"
            timeFormat="H:mm "
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-5 col-12">
          <h6>Defendido:</h6>
        </div>
        <div className="col-sm-3 col-12">
          <label>
            <input
              className="mr-2"
              value="1"
              checked={defended}
              onChange={() => setDefended(true)}
              type="radio"
            />
            Si
          </label>
        </div>
        <div className="col-sm-3 col-12">
          <label>
            <input
              className="mr-2"
              value="0"
              checked={!defended}
              onChange={() => setDefended(false)}
              type="radio"
            />
            No
          </label>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-end ">
          <button
            className="button-save mt-1"
            style={styleIsAllowed(disabled)}
            disabled={disabled}
            onClick={() => handleUpdate()}
          >
            <FontAwesomeIcon className="mr-2" icon={faSave} />
            Guardar
          </button>
        </div>
      </div>
    </>
  );
};
