/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  faAward,
  faBuilding,
  faGraduationCap,
  faIndustry,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

interface IProps {
  title: string;
  number: number;
}

export const Badge = ({ title, number }: IProps) => {
  useEffect(() => {
    buildStyles();
  }, []);

  const [backColor, setBackColor] = useState("");

  const buildStyles = () => {
    switch (title) {
      case "Empresas":
        return setBackColor("#189BCC");
      case "TFGs":
        return setBackColor("#12385E");
      case "TFMs":
        return setBackColor("#5D6163");
      case "Centros":
        return setBackColor("#CD176F");
      default:
        return setBackColor("");
    }
  };

  const buildIcon = () => {
    switch (title) {
      case "Empresas":
        return <FontAwesomeIcon style={{ fontSize: "2.5rem" }} icon={faIndustry} />;

      case "TFGs":
        return (
          <FontAwesomeIcon style={{ fontSize: "2.5rem" }} icon={faGraduationCap} />
        );

      case "TFMs":
        return <FontAwesomeIcon style={{ fontSize: "2.5rem" }} icon={faAward} />;

      case "Centros":
        return <FontAwesomeIcon style={{ fontSize: "2.5rem" }} icon={faBuilding} />;

      default:
        return setBackColor("");
    }
  };

  const style = {
    mainDiv: {
      backgroundColor: backColor,
      justifyContent: "space-evenly",
      alignItems: "center",
      color: "white",
      display: "flex",
    },
    font: {
      fontSize: "1.6rem",
      fontWeight: 700,
    },
  };

  return (
    <div className="whiteDiv" style={style.mainDiv}>
      {buildIcon()}
      <div className="d-flex flex-column ml-3">
        <div>{title}</div>
        <div style={style.font}>{number}</div>
      </div>
    </div>
  );
};
