/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

//middleware
import Axios from "axios";
import { More } from "../../../components/More/More";

//utils
import { UsersTable } from "../../../components/UsersTable/UsersTable";
import { ROUTES } from "../../../utils/routes";
import { normalizer } from "../../../utils/auxiliar/strings";
import { ICenter } from "../../../interfaces/ICenter";
import { paths } from "../../../utils/paths";
import { getAll, getById } from "../../../utils/requests/dynamicReq";
import { activeFilter, buildFilter } from "../Common/ManageTFE/utils";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { role } from "../../../utils/roles";


export const UsersList: FC = () => {
  const history = useHistory();
  const { id: userId, userRole } = useSelector(({ user }: IStore) => user as IUser);

  const { CENTERS, USERS, USERSBYROLE, DELEGATESBYCENTER } = paths;

  const [coordinators, setCoordinators] = useState<ICenter[]>([]);
  const [displayCoordinators, setDisplayCoordinators] = useState<ICenter[]>([]);
  const [centers, setCenters] = useState<ICenter[]>([]);

  //Filters
  const [pickedCenter, setPickedCenter] = useState("");
  const [search, setSearch] = useState("");

  const [index, setIndex] = useState(75);
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    setIsLoading(true);
    userRole === role.admin ? loadDefaultData() : loadDefaultCoordinatorData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadDefaultData = async () => {
    try {
      const filterCoordinator: string = buildFilter({ userRole: "coordinator" });
      const filterDelegate: string = buildFilter({ userRole: "delegate" });
      const responseCenters = await getAll(CENTERS, source);

      const coordinators = await getAll(USERSBYROLE, source, filterCoordinator);
      const delegates = await getAll(USERSBYROLE, source, filterDelegate);

      if (isMountedRef.current) {
        setIsLoading(false);
        setCenters(responseCenters);
        coordinators.map((a: any) => (a.userRole = "Coordinador"));
        delegates.map((a: any) => (a.userRole = "Delegado"));
        setCoordinators(coordinators.concat(delegates));
        setDisplayCoordinators(coordinators.concat(delegates));
      }
    } catch (error) {
      setIsLoading(false);
      console.error("HTTP call cancelled");
    }
  };

  const loadDefaultCoordinatorData = async () => {
    try {
      const userResponse = await getById(USERS, userId, source);
      if (isMountedRef.current) {
        setIsLoading(false)
        loadDelegatesData(userResponse?.centers[0]?.id);
      }
    } catch (error) {
      setIsLoading(false)
      console.error("HTTP call cancelled");
    }
  };

  const loadDelegatesData = async (centerId: number) => {
    try {
      const filter: string = activeFilter({ active: "active", option: true });

      const delegates = await getById(DELEGATESBYCENTER, centerId, source, filter);

      if (isMountedRef.current) {
        delegates.map((a: any) => (a.userRole = "Delegado"));
        setCoordinators(delegates);
        setDisplayCoordinators(delegates);
      }
    } catch (error) {
      console.error("HTTP call cancelled");
    }
  };

  const filterCenter = (pickedCenter: string) => {
    setPickedCenter(pickedCenter);
    searchFilter(search, pickedCenter);
  };

  const searchFilter = (value: any, pickedCenter: string) => {
    setSearch(value);
    !value && setCoordinators(displayCoordinators);
    value = normalizer(value.toLowerCase());

    setCoordinators(
      displayCoordinators.filter(({ fullName, userRole, centers }: any) => {
        return (
          ((fullName && normalizer(fullName).includes(value)) ||
            (userRole && normalizer(userRole).includes(value))) &&
          (pickedCenter === "" || centers[0]?.name === pickedCenter)
        );
      })
    );
  };

  const cleanFilters = () => {
    setSearch("");
    setPickedCenter("");
    setCoordinators(displayCoordinators);
  };

  //------------------------------------------

 
  const admin = userRole === role.admin;

  let header = "Usuarios";

  return (
    <>
      <div className="container-fluid pl-4">
        <div className="row mb-4">
          <div className="col-12">
            <h5>{header}</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-5 col-12">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar..."
              value={search}
              onChange={({ target: { value } }) => searchFilter(value, pickedCenter)}
            />
          </div>
          {admin && <div className="col-sm-3 col-12">
            <select
              className="form-control"
              value={pickedCenter}
              onChange={({ target: { value } }) => filterCenter(value)}
            >
              <option value={""}>Centro</option>
              {centers.map(({ name, id }) => (
                <option key={id} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>}

          <div className="col-sm-2 col-12">
            <button className="button-search mt-2 mr-5" onClick={cleanFilters}>
              Quitar filtros
            </button>
          </div>

          <div className="col-sm-2 col-12">
            <button
              className="button-search mt-2 "
              onClick={() => history.push(ROUTES.Common.Coordinators.create)}
            >
              Nuevo delegado
            </button>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 whiteDiv overFlowAuto">
            <UsersTable
              coordinators={coordinators}
              index={index}
            />
            <More
              displayData={displayCoordinators}
              isLoading={isLoading}
              refreshIndex={setIndex}
              index={index}
              amount={50}
            />
          </div>
        </div>
      </div>
    </>
  );
};
