import { RadialCharts } from "../RadialCharts";
import { buildCenterRadialData } from "../utils";
import { CenterIndex } from "./CenterIndex";

interface IProps {
  data: any[];
  loading: boolean;
}

export const SectionCenters = ({ data, loading }: IProps) => {
  const { tfgArray, tfmArray } = buildCenterRadialData(data);

  return (
    <div className="row">
      <div className="col-sm-6 col-12  mt-4">
        <div className="d-flex  whiteDiv responsiveColumn">
          <div className="col-sm-12 col-12 center flex-column">
            {loading ? (
              <span className="spinner-grow bg-pink m-5" />
            ) : (
              !!!tfgArray.length && (
                <p className="m-5 text-center">No hay suficientes datos de TFGs</p>
              )
            )}

            {!!tfgArray.length && (
              <RadialCharts
                data={tfgArray}
                innerRadius={100}
                radius={140}
                width={400}
                height={300}
                label="TFG"
              />
            )}
            <div>
              <CenterIndex />
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-12  mt-4">
        <div className="d-flex  whiteDiv responsiveColumn">
          <div className="col-sm-12 col-12 center flex-column">
            {loading ? (
              <span className="spinner-grow bg-pink m-5" />
            ) : (
              !!!tfmArray.length && (
                <p className="m-5 text-center">No hay suficientes datos de TFMs</p>
              )
            )}

            {!!tfmArray.length && (
              <RadialCharts
                data={tfmArray}
                innerRadius={100}
                radius={140}
                width={300}
                height={300}
                label="TFM"
              />
            )}
            <div>
              <CenterIndex />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
